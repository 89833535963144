/**
 * 易理货base微服务工程接口
 */

const PREFIX = `/ylh-cloud-service-stock${process.env.VUE_APP_SERVER_SUFFIX}`

// 接口列表
export const CloudStock = {
  // 首页-待处理业务-库存待办
  pendingStockData: `${PREFIX}/api/page/stock/management/get-stock-wait-info-count`,
  // 首页-零售业务-库存详情
  retailBusinessStockData: `${PREFIX}/api/page/stock/management/get-stock-info-count`,
  // 库存待办信息列表
  searchStockWaitInfoList: `${PREFIX}/api/page/stock/management/search-stock-wait-info-list`
}
