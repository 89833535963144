import Vue from 'vue'
import i18n from './i18n'
import {
  Icon,
  Button,
  Dialog,
  Breadcrumb,
  BreadcrumbItem,
  DatePicker,
  MessageBox,
  Message,
  Loading,
  Notification,
  Card,
  Input,
  RadioGroup,
  Radio,
  Row,
  Col,
  Select,
  Option,
  Carousel,
  CarouselItem,
  Tabs,
  TabPane,
  Tag,
  Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  RadioButton,
  Drawer,
  Pagination,
  Popover,
  Badge,
  Link,
  Avatar
} from 'element-ui'

Vue.prototype.$ELEMENT = {
  size: 'small',
  zIndex: 3000,
  i18n: (key, value) => i18n.t(key, value)
}
Vue.use(Icon)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(DatePicker)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Input)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(Row)
Vue.use(Col)
Vue.use(Select)
Vue.use(Option)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tag)
Vue.use(Tooltip)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Drawer)
Vue.use(Pagination)
Vue.use(Popover)
Vue.use(Badge)
Vue.use(Link)
Vue.use(Avatar)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message
