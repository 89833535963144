'use strict'

import Vue from 'vue'
import axios from 'axios'
import Http from '../utils/http'
import Store from '../store'
import {
  interceptReq,
  interceptReqReject,
  interceptRes,
  interceptResReject,
  logout
} from './axios-helper'
import Router from '../router'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  timeout: 30 * 1000 // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

// 接口白名单 这里面的接口地址不会在 headers 里面添加访问 token
const WHITE_LIST = []

// 请求拦截处理
const interceptorRequest = (config) => {
  if (window.navigator.onLine) {
    if (!Store.state.network.onLine) {
      Store.commit('changeNetworkOnline', true)
    }

    // 初始化请求头
    if (!config.headers) { config.headers = {} }
    const path = config.url?.split('?')[0]
    // const accessToken = getToken()
    // const refreshToken = getRefreshToken()

    // 白名单接口直接放过
    if (WHITE_LIST.includes(path)) {
      return config
    }

    return config
  } else {
    Store.commit('changeNetworkOnline', false)
    Store.commit('changeBeforeOfflinePath', location.pathname)
    // Router.push('/offline')
    return config
  }
}

// 请求拒绝拦截
const interceptorRequestReject = (error) => {
  return Promise.reject(error)
}

// 响应拦截处理
const interceptorResponse = (response) => {
  const { config } = response
  if (process.env.NODE_ENV === 'production') {
    console.log('HTTP:', {
      url: config.url,
      'jsh-request-id': response.headers['jsh-request-id']
    })
  }
  if (
    config.responseType === 'blob' ||
    config.responseType === 'arraybuffer'
  ) {
    return response
  }
  if (response && response.request && response.request.responseText) {
    const emp = response.request.responseText.includes('<!DOCTYPE html>')
    if (emp) {
      if (process.env.NODE_ENV === 'development') {
        Router.push('/login')
      } else {
        logout()
      }
    }
  }
  return response
}

// 响应拒绝拦截
const interceptorResponseReject = (error) => {
  const { response } = error
  if (response) {
    switch (response.status) {
      case 401:
        if (process.env.NODE_ENV === 'development') {
          Router.push('/login')
        } else {
          logout()
        }
        break
      case 500:
        // 服务器错误
        break
      case 404:
        // 找不到接口
        break
      default:
        break
    }
    return Promise.reject(error.response)
  }

  // Do something with response error
  return Promise.reject(error)
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  (config) => {
    const mainResult = interceptReq(config)
    if (mainResult) { return mainResult }

    const mainReq = interceptorRequest(config)
    if (mainReq) { return mainReq }
    return config
  },
  (error) => {
    const mainResult = interceptReqReject(error)
    if (mainResult) { return mainResult }

    const mainReqReject = interceptorRequestReject(error)
    if (mainReqReject) { return mainReqReject }
    return error
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    const mainResult = interceptRes(response)
    if (mainResult) { return mainResult }

    const mainRes = interceptorResponse(response)
    if (mainRes) { return mainRes }
    return response
  },
  (error) => {
    const mainResult = interceptRes(error)
    if (mainResult) { return mainResult }

    const mainResReject = interceptorResponseReject(error)
    if (mainResReject) { return mainResReject }
    return error
  }
)

Plugin.install = function (Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    $http: {
      get () {
        return Http(_axios)
      }
    }
  })
}

if (process.env.VUE_APP_RUN_MAIN_APP === 'true') {
  Vue.use(Plugin)
}

export default {
  interceptReq,
  interceptReqReject,
  interceptRes,
  interceptResReject,
  logout
}
