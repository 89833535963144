var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.bem()},[_c('div',[_c('div',{class:_vm.bem('menu', ['home'])},[_c('div',{staticClass:"clearfix",class:[_vm.bem('menu-title'), _vm.activeMenuId === 0 ? 'active' : null],on:{"click":_vm.toHomePage}},[_c('div',{staticClass:"menu-title-icon float-left"},[(_vm.activeMenuId === 0)?_c('img',{attrs:{"src":require("../../assets/images/homeLine.png"),"alt":""}}):_c('img',{attrs:{"src":require("../../assets/images/home.png"),"alt":""}})]),_c('div',{staticClass:"menu-title-text text-truncate float-left"},[_vm._v(" 首页 ")])])]),_c('div',{staticClass:"js-hover-menu",class:_vm.bem('menu', 'follow')},[_c('div',{staticClass:"clearfix",class:_vm.bem('menu-title')},[_vm._m(0),_c('div',{staticClass:"menu-title-text text-truncate float-left"},[_vm._v(" 常用 ")])]),_c('div',{ref:"menu-wrap_common",staticClass:"sub-menu-warp"},[(_vm.commonMenuList.length !== 0)?_c('div',[_c('div',{staticClass:"clearfix common-menu-header"},[_c('div',{staticClass:"float-left common-menu-header-title"},[_vm._v(" 快捷入口 ")]),_c('div',{staticClass:"float-right common-menu-header-btn",on:{"click":() => {
                  _vm.$store.commit('changeDisplayAppManageModal', true);
                }}},[_vm._v(" +新增 ")])]),_c('ul',{directives:[{name:"scroll",rawName:"v-scroll"}],staticClass:"clearfix common-menu-warp",on:{"mouseover":_vm.handleMouseOver}},_vm._l((_vm.commonMenuList),function(commonMenu,index){return _c('li',{key:index,staticClass:"common-menu float-left d-flex align-items-center"},[_c('span',{staticClass:"text-hover cursor-pointer d-inline-block text-truncate",attrs:{"title":commonMenu.menuName},on:{"click":function($event){return _vm.toPageForCommon(commonMenu)}}},[_vm._v(" "+_vm._s(commonMenu.menuName)+" ")]),(commonMenu.promotionUrl)?_c('img',{staticClass:"ml-5",staticStyle:{"width":"34px"},attrs:{"src":commonMenu.promotionUrl}}):_vm._e()])}),0)]):_vm._e(),(_vm.commonMenuList.length === 0)?_c('div',{staticClass:"clearfix",staticStyle:{"width":"720px","padding":"30px 30px 30px 160px","text-align":"center"}},[_vm._m(1),_c('div',{staticClass:"float-left",staticStyle:{"line-height":"40px","margin-left":"10px"}},[_c('span',{staticStyle:{"color":"#fff","opacity":"0.6"}},[_vm._v(" 你还没有常用功能，快去添加吧 ")]),_c('a',{staticClass:"add-commonMenu",attrs:{"href":"javascript:;"},on:{"click":() => {
                  _vm.$store.commit('changeDisplayAppManageModal', true);
                }}},[_vm._v(" 去添加> ")])])]):_vm._e(),_c('app-manage',{attrs:{"visible":_vm.manageModal}})],1)]),_c('div',{staticStyle:{"height":"calc(100vh - 238px)"},attrs:{"id":"i-scroll-js"}},[_c('div',[_vm._l((_vm.menuList),function(menu,index){return _c('div',{key:index,staticClass:"js-hover-menu",class:_vm.bem('menu')},[(menu.name === '统计')?_c('a',{staticClass:"menu-title d-block clearfix",class:[_vm.bem('menu-title'), menu.id === _vm.activeMenuId ? 'active': null],attrs:{"href":menu.pcUrl ? menu.pcUrl : 'javascript:;',"id":"homeStep2"}},[_c('div',{staticClass:"menu-title-icon float-left"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id !== _vm.activeMenuId),expression:"menu.id !== activeMenuId"}],attrs:{"src":menu.iconUrl ? menu.iconUrl : _vm.menuDefaultImg,"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id === _vm.activeMenuId),expression:"menu.id === activeMenuId"}],attrs:{"src":menu.selectedIconUrl
                    ? menu.selectedIconUrl
                    : _vm.menuDefaultImgLine,"alt":""}})]),_c('div',{staticClass:"menu-title-text text-truncate float-left"},[_vm._v(" "+_vm._s(menu.name)+" ")])]):(menu.name === '营销')?_c('a',{staticClass:"menu-title d-block clearfix",class:[_vm.bem('menu-title'), menu.id === _vm.activeMenuId ? 'active': null],attrs:{"href":menu.pcUrl ? menu.pcUrl : 'javascript:;',"id":"homeStep3"}},[_c('div',{staticClass:"menu-title-icon float-left"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id !== _vm.activeMenuId),expression:"menu.id !== activeMenuId"}],attrs:{"src":menu.iconUrl ? menu.iconUrl : _vm.menuDefaultImg,"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id === _vm.activeMenuId),expression:"menu.id === activeMenuId"}],attrs:{"src":menu.selectedIconUrl
                    ? menu.selectedIconUrl
                    : _vm.menuDefaultImgLine,"alt":""}})]),_c('div',{staticClass:"menu-title-text text-truncate float-left"},[_vm._v(" "+_vm._s(menu.name)+" ")])]):_c('a',{staticClass:"menu-title d-block clearfix",class:[_vm.bem('menu-title'), menu.id === _vm.activeMenuId ? 'active': null],attrs:{"href":menu.pcUrl ? menu.pcUrl : 'javascript:;'}},[_c('div',{staticClass:"menu-title-icon float-left"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id !== _vm.activeMenuId),expression:"menu.id !== activeMenuId"}],attrs:{"src":menu.iconUrl ? menu.iconUrl : _vm.menuDefaultImg,"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id === _vm.activeMenuId),expression:"menu.id === activeMenuId"}],attrs:{"src":menu.selectedIconUrl
                    ? menu.selectedIconUrl
                    : _vm.menuDefaultImgLine,"alt":""}})]),_c('div',{staticClass:"menu-title-text text-truncate float-left"},[_vm._v(" "+_vm._s(menu.name)+" ")])]),_c('ul',{ref:`menu-wrap_${menu.id}`,refInFor:true,staticClass:"sub-menu-warp clearfix"},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll"}],staticClass:"menu-wrap-box",on:{"mouseover":_vm.handleMouseOver}},_vm._l((menu.children),function(subMenu,subIndex){return _c('li',{key:subIndex,staticClass:"float-left sub-menu"},[_c('a',{staticClass:"sub-menu-title",attrs:{"href":subMenu.pcUrl ? subMenu.pcUrl : 'javascript:;'}},[_vm._v(" "+_vm._s(subMenu.name)+" ")]),_vm._l((subMenu.children),function(thirdMenu,thirdIndex){return _c('div',{key:thirdIndex,staticClass:"clearfix third-menu-warp",on:{"click":function($event){return _vm.toPage(menu, subMenu, thirdMenu)}}},[_c('div',{staticClass:"float-left third-menu-title",attrs:{"title":thirdMenu.name}},[_c('span',[_vm._v(_vm._s(thirdMenu.name))]),(thirdMenu.promotionUrl)?_c('div',{staticClass:"d-inline-flex align-items-center ml-2"},[_c('img',{staticStyle:{"width":"34px"},attrs:{"src":thirdMenu.promotionUrl,"alt":""}})]):_vm._e()])])})],2)}),0)])])}),_c('div',{class:_vm.bem('menu')},[_c('a',{staticClass:"d-block clearfix",class:_vm.bem('menu-title'),on:{"click":_vm.openHelpCenter}},[_vm._m(2),_c('div',{staticClass:"menu-title-text text-truncate float-left"},[_vm._v(" 帮助中心 ")])])])],2)]),_c('div',{ref:"sidebarToTop",staticClass:"sidebar-to-top",staticStyle:{"display":"none"}}),_c('div',{ref:"sidebarToBottom",staticClass:"sidebar-to-bottom",staticStyle:{"display":"none"}})]),_c('div',{staticClass:"app-manage application-center",on:{"click":_vm.toAppManagePage}},[_vm._m(3),_c('div',{staticClass:"menu-title-text text-truncate float-left"},[_vm._v(" 应用中心 ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-title-icon float-left",staticStyle:{"opacity":"1"}},[_c('img',{attrs:{"src":require("../../assets/images/follow.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float-left"},[_c('img',{attrs:{"src":require("../../assets/images/no-data.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-title-icon float-left"},[_c('img',{attrs:{"src":require("../../assets/images/help-sidebar.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-manage-icon float-left"},[_c('img',{attrs:{"src":require("../../assets/images/app-manage.png")}})])
}]

export { render, staticRenderFns }