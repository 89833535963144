export default {
  permission (state) {
    const obj = {}
    state.permissionList.forEach(key => {
      obj[key] = true
    })
    return obj
  },
  headerBarHeight (state) {
    return state.isIframe ? 0 : 70
  }
}
