<template>
  <div class="text-center vh-100 d-flex flex-column justify-content-center align-items-center">
    <img
      src="../../assets/images/403.png"
      style="width: 320px"
    >
    <div class="pt-40 fs-24" style="color: #4D5B69">
      对不起！网址无法直接访问，请从规定入口登录
    </div>
  </div>
</template>
<script>
export default {}
</script>
