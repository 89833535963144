/**
 * 易理货base微服务工程接口
 */

const PREFIX = `/ylh-cloud-service-fund${process.env.VUE_APP_SERVER_SUFFIX}`

// 接口列表
export const CloudFund = {
  // 首页-待处理业务-分销订单-待处理收款
  pendingReceivables: `${PREFIX}/api/page/fund/center/income-expenses-search/search-fund-center-statistics-pc`
}
