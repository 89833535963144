export default [
  {
    path: '/login',
    component: () => import('../views/login/login.vue'),
    meta: {
      keepAlive: true,
      title: '登录',
      User: true,
      wx: true
    }
  },
  {
    path: '/login-single',
    name: 'loginSingle',
    component: () => import('../views/login-single/login-single.vue'),
    meta: {
      keepAlive: false,
      title: '正在登录',
      User: true
    }
  },
  {
    path: '/user-agreement',
    name: 'userAgreement',
    component: () =>
      import('../views/agreement/user-agreement/user-agreement.vue'),
    meta: {
      keepAlive: false,
      title: '易理货用户服务协议'
    }
  },
  {
    path: '/privacy-agreement',
    name: 'privacyAgreement',
    component: () =>
      import('../views/agreement/privacy-agreement/privacy-agreement.vue'),
    meta: {
      keepAlive: false,
      title: '易理货个人信息保护政策'
    }
  },
  {
    path: '/link/:key',
    name: 'link',
    component: () => import('../views/app-link/app-link.vue'),
    meta: {
      keepAlive: false,
      title: '易理货'
    }
  },
  {
    path: '/update',
    name: 'Update',
    component: () =>
      import('../views/update/update.vue'),
    meta: {
      keepAlive: false,
      title: '升级说明'
    }
  },
  {
    path: '/business-license',
    name: 'BusinessLicense',
    component: () =>
      import('../views/business-license/business-license.vue'),
    meta: {
      keepAlive: false,
      title: '营业执照'
    }
  },
  {
    path: '/advert-detail',
    name: 'advertDetail',
    component: () => import('../views/advert-detail/advert-detail.vue'),
    meta: {
      keepAlive: false,
      title: '广告详情',
      User: true
    }
  },
  {
    path: '/kingdee-audit',
    name: 'Kingdee-audit',
    component: () =>
      import('../views/kingdee-audit/kingdee-audit.vue'),
    meta: {
      keepAlive: false,
      title: '金蝶云星辰'
    }
  },
  {
    path: '/in-the-review',
    name: 'in-the-review',
    component: () =>
      import('@/views/kingdee-audit/components/in-the-review.vue'),
    meta: {
      keepAlive: false,
      title: '金蝶云星辰'
    }
  }
]
