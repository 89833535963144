<template>
  <div class="redirect-http-request">
    <div class="open-trigger" :class="{ alert: changedList.length, 'is-iframe': isIframe }" @click="show = true">
      <i class="el-icon-info"/>
      DEV专用：
      <template v-if="!changedList.length">
        当前API请求均通过 {{ currentSuffix }} 进行访问
      </template>
      <template v-else>
        {{ changedList.map(_ => `API-${_.label} => ${_.suffix}`).join('; ') }}
      </template>
    </div>
    <el-dialog
      :visible.sync="show"
      title="重定向API请求"
      width="700px"
      append-to-body
      dialog-center
      @closed="onClosed"
    >
      <div class="dialog-container">
        <div class="tips">
          <i class="el-icon-info"/>
          <div>
            请求重定向更改仅Main工程页面可实时生效。
            <br>
            在其他工程（如Cloud）中，更改设置后关闭Dialog页面将会自动刷新。
          </div>
        </div>
        <el-table :data="list" :row-class-name="tellRowStatus" max-height="500">
          <el-table-column prop="label" label="后端工程" width="150"/>
          <el-table-column prop="prefix" label="请求前缀"/>
          <el-table-column>
            <template v-slot:header>
              请求后缀
              <el-button type="text" icon="el-icon-refresh-left" @click="onReset()"/>
            </template>
            <template v-slot="{ row }">
              <el-input v-model.trim="row.suffix" size="mini" @blur="onSave">
                <el-button
                  slot="append"
                  icon="el-icon-refresh-left"
                  @click="onReset(row)"
                />
              </el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog, Table, TableColumn, Input, Button, Message } from 'element-ui'
import { tellIsIframe } from '../../utils'

Vue.use(Dialog).use(Table).use(TableColumn).use(Input).use(Button)

const STORE_KEY = 'dev_redirect_http_request_list'
const CURRENT_SUFFIX = process.env.VUE_APP_SERVER_SUFFIX.slice(1)

export default {
  name: 'RedirectHttpRequest',
  props: {
    vuePrototype: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isIframe: tellIsIframe(),
      show: false,
      saved: false,
      list: [],
      currentSuffix: CURRENT_SUFFIX,
      lastSave: ''
    }
  },
  computed: {
    changedList () {
      return this.list.filter(_ => _.suffix !== CURRENT_SUFFIX)
    }
  },
  created () {
    this.lastSave = window.localStorage.getItem(STORE_KEY) || ''

    const prevList = JSON.parse(this.lastSave || '[]')
    const curList = [
      { label: 'Base', prefix: 'ylh-cloud-service-base' },
      { label: 'Exchange', prefix: 'ylh-cloud-service-exchange' },
      { label: 'Fund', prefix: 'ylh-cloud-service-fund' },
      { label: '金蝶', prefix: 'jsh-jindie-job-executor' },
      { label: 'Goods', prefix: 'ylh-cloud-service-goods' },
      { label: 'Order', prefix: 'ylh-cloud-service-order' },
      { label: 'Policy', prefix: 'ylh-cloud-service-policy' },
      { label: 'Stock', prefix: 'ylh-cloud-service-stock' },
      { label: 'User', prefix: 'ylh-cloud-service-user' },
      { label: 'Unipay', prefix: 'ylh-cloud-service-unipay' }
    ]

    this.vuePrototype.$redirectHttpRequestList = curList.map(item => {
      const target = prevList.find(_ => _.label === item.label)
      return {
        ...item,
        suffix: target ? target.suffix : CURRENT_SUFFIX
      }
    })
    this.list = this.vuePrototype.$redirectHttpRequestList
  },
  methods: {
    tellRowStatus ({ row }) {
      return row.suffix !== CURRENT_SUFFIX ? 'changed-row' : ''
    },
    onSave () {
      const curSave = JSON.stringify(this.list)
      if (curSave !== this.lastSave) {
        window.localStorage.setItem(STORE_KEY, curSave)

        this.saved = true
        this.lastSave = curSave

        Message.success('设置已保存')
      }
    },
    onReset (row) {
      if (row) {
        row.suffix = CURRENT_SUFFIX
      } else {
        this.list.forEach(_ => {
          _.suffix = CURRENT_SUFFIX
        })
      }

      this.onSave()
    },
    onClosed () {
      if (this.saved && !window.location.pathname.startsWith('/webv2')) {
        window.location.reload()
      }

      this.saved = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .redirect-http-request {
    .open-trigger {
      position: fixed;
      top: 70px;
      right: 20px;
      padding: 10px 20px;
      color: #409EFF;
      line-height: 14px;
      cursor: pointer;
      transition: 200ms;

      &.is-iframe {
        top: 0;
      }

      &:hover {
        color: #ffffff;
        background: #409EFF
      }

      &.alert {
        color: #ffffff;
        background: #f56c6c;

        &:hover {
          filter: brightness(1.1);
        }
      }

      i {
        margin-right: 5px;
      }
    }
  }

  .dialog-container {
    .tips {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      padding: 10px 20px;
      color: #409EFF;
      font-size: 14px;
      line-height: 20px;
      background: #ECF5FF;
      border-radius: 4px;

      i {
        margin-right: 10px;
      }
    }

    .el-table {
      &::v-deep {
        .changed-row {
          color: #f56c6c;

          .el-input__inner {
            color: #f56c6c;
          }
        }

        .el-button--text {
          padding: 0;
        }
      }
    }

    .el-input {
      width: 150px;
      font-size: 14px;
    }
  }
</style>
