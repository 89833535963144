/**
 * 易理货base微服务工程接口
 */

const PREFIX = `/ylh-cloud-service-bi${process.env.VUE_APP_SERVER_SUFFIX}`

// 接口列表
export const CloudBi = {
  // 获取库存信息
  getstockinfocount: `${PREFIX}/api/page/stock/management/get-stock-info-count`
}
