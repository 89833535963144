import { storage } from '@jsh/helper/utils'

export default {
  // 网络
  network: {
    // 是否有网
    online: true,
    // 断网前的页面地址
    beforeOfflinePath: storage.getItem('beforeOfflinePath') || ''
  },
  // 用户信息
  userInfo: storage.getItem('userInfo') || {},
  commonMenuList: [],
  isDisplayAppManageModal: false,
  breadcrumbList: [],
  permissionList: [],
  pricePermissionList: [],
  activeMenuId: 0,
  // 是否在 iframe 中，当值为字符串时意味着处于某个特定的iframe环境中，比如jsh（巨商汇）
  isIframe: false
}
