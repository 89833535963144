import '@jsh/intro.js/dist/introjs.css'
export const jshIntro = require('@jsh/intro.js/dist/intro.js')

// interface Step {
//   element?: string;
//   intro: string;
//   position?: string;
// }
//
// interface Options {
//   steps: Step[]; // 引导步骤
//   prevLabel?: string; // 返回上一步的按钮的字符串
//   nextLabel?: string; // 返回下一步的按钮的字符串
//   skipLabel?: string; // 跳过引导的按钮的字符串
//   doneLabel?: string; // 结束按钮的字符串
//   showBullets?: boolean; // 是否使用点点点显示进度
//   highlightClass?: string; // 高亮所使用的class名
//   hidePrev?: boolean; // 是否在第一步隐藏上一步
//   hideNext?: boolean; // 是否在最后一步隐藏下一步
//   showStepNumbers?: boolean; // 是否显示当前步骤的索引数字
//   scrollToElement?: boolean; // 是否滑动到高亮的区域
//   showButtons?: boolean; // 是否显示所有按钮
//   disableInteraction?: boolean; // 引导框内视图是否可交互
//   tooltipClass?: string; // 提示框所使用的class名
//   showProgress?: boolean; // 是否显示进度条
//   overlayOpacity?: number; // 蒙版的透明度
//   exitOnEsc?: boolean; // 是否使用键盘Esc退出
//   exitOnOverlayClick?: boolean; // 是否允许点击空白处退出
//   helperElementPadding?: number; // 提示边框的padding
//   hintButtonLabel?: string; // 默认提示内容
//   hintPosition?: string; // 默认提示位置
// }

const baseOptions = {
  steps: [],
  prevLabel: '上一步',
  nextLabel: '下一步',
  skipLabel: '跳过',
  doneLabel: '结束',
  showBullets: false,
  highlightClass: 'introjs',
  hidePrev: true,
  hideNext: false,
  showStepNumbers: false,
  scrollToElement: true,
  disableInteraction: false,
  tooltipClass: 'intro-no-pre',
  showProgress: false,
  overlayOpacity: 0.5,
  exitOnEsc: false,
  exitOnOverlayClick: false,
  helperElementPadding: 1
}

const getIntro = (options) => {
  // eslint-disable-next-line new-cap
  return new jshIntro().setOptions(
    Object.assign(JSON.parse(JSON.stringify(baseOptions)), options)
  )
}
// 首页
export const homeIntro = (options) => {
  const baseOps = {
    skipLabel: '跳过引导',
    prevLabel: '上一步',
    nextLabel: '下一步',
    doneLabel: '开启征程',
    hidePrev: true, // 在第一步中是否隐藏上一个按钮
    scrollToElement: true, // 是否滑动到高亮的区域
    showBullets: false, // 是否使用点点点显示进度
    exitOnEsc: false, // 是否允许点击空白处退出
    highlightClass: 'fixedTooltip', // 说明高亮区域的样式
    tooltipClass: 'tooltip', // 引导说明文本框的样式
    exitOnOverlayClick: false,
    steps: options
  }
  return getIntro(baseOps)
}

// 卡券
export const cardCoupon = (options) => {
  const baseOps = {
    showButtons: true,
    disableInteraction: true,
    highlightClass: 'fixedTooltip', // 说明高亮区域的样式
    doneLabel: '查看详情',
    tooltipClass: 'tooltip', // 引导说明文本框的样式
    steps: options
  }
  return getIntro(baseOps)
}

// 入户服务
export const HomeService = (options) => {
  const baseOps = {
    showButtons: true,
    disableInteraction: true,
    highlightClass: 'fixedTooltip', // 说明高亮区域的样式
    doneLabel: '视频教学',
    tooltipClass: 'tooltip', // 引导说明文本框的样式
    steps: options
  }
  return getIntro(baseOps)
}
