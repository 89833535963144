<template>
  <el-drawer
    title="管理常用"
    :visible.sync="visible"
    size="1000px"
    custom-class="common-warp"
    @close="
      () => {
        store.commit('changeDisplayAppManageModal', false);
      }
    "
  >
    <div :class="bem()">
      <!--弹出框-->
      <div :class="bem('container')">
        <div :class="bem('modal-content')" v-loading="loading">
          <!--tab-->
          <div
            class="pl-20 pb-20 pr-20"
          >
            <ul
              class="clearfix"
              :class="bem('header')"
            >
              <li
                class="float-left"
                :class="[bem('li'), selectedMenuData.id === menu.id ? 'active' : null]"
                v-for="(menu, index) of sidebarMenuList"
                :key="index"
                @click="selectedMenuData = menu"
              >
                <span :class="bem('span')">
                  {{ menu.name }}
                </span>
              </li>
            </ul>
          </div>
          <!--一级content-->
          <template v-for="(menu, index) of sidebarMenuList">
            <div
              v-show="selectedMenuData.id === menu.id"
              v-scroll
              :key="index"
              :class="bem('content-menu')"
              style="width: 1000px;overflow: auto;position: relative"
            >
              <div
                class="clearfix"
                :class="[bem('content'), selectedMenuData.id === menu.id ? 'active': null]"
                :style="{ width: menu.children.length * 163 + 120 + 'px' }"
              >
                <!--二级-->
                <div
                  class="float-left"
                  :class="bem('content-sub')"
                  v-for="(subMenu, subIndex) of menu.children"
                  :key="subIndex"
                >
                  <div :class="bem('sub-title')">
                    {{ subMenu.name }}
                  </div>
                  <ul :class="bem('sub-third')">
                    <!--三级-->
                    <li
                      v-for="(thirdMenu, thirdIndex) of subMenu.children"
                      :key="thirdIndex"
                      class="relative"
                    >
                      <span class="mr-5" :class="{ 'color-303133': thirdMenu.isCommon }">{{ thirdMenu.name }}</span>
                      <img
                        v-if="thirdMenu.promotionUrl"
                        :src="thirdMenu.promotionUrl"
                        style="width: 34px;"
                      >
                      <label>
                        <img
                          v-show="thirdMenu.isCommon"
                          src="../../assets/images/menu-select.png"
                          width="16px"
                        >
                        <img
                          v-show="!thirdMenu.isCommon"
                          @click="changeCommonMenu(thirdMenu)"
                          src="../../assets/images/menu-unselect.png"
                          class="un-select cursor-pointer"
                          width="16px"
                        >
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { Default, CloudUser } from '../../apis'
import storeMixin from '../../mixins/store'
import bemMixin from '../../mixins/class-name'
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

const elScrollBar = (el) => {
  if (el._ps_ instanceof PerfectScrollbar) {
    el._ps_.update()
  } else {
    el._ps_ = new PerfectScrollbar(el, {})
  }
}

export default {
  directives: {
    scroll: {
      inserted (el, binding, vnode) {
        const rules = ['fixed', 'absolute', 'relative']
        if (!rules.includes(getComputedStyle(el).position || '')) {
          console.error(
            `perfect-scrollbar所在的容器的position属性必须是以下之一：${rules.join(
              '、'
            )}`
          )
        }
        elScrollBar(el)
      },
      // 更新dom的时候
      componentUpdated (el, binding, vnode, oldVnode) {
        try {
          vnode.context.$nextTick(() => {
            elScrollBar(el)
          })
        } catch (error) {
          console.error(error)
          elScrollBar(el)
        }
      }
    }
  },
  mixins: [storeMixin, bemMixin('app-manage-modal')],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menuList: [], // 菜单列表
      // 选择的一级菜单
      selectedMenuData: {
        id: -1,
        name: '',
        pcUrl: '',
        children: []
      },
      loading: false,
      isInit: false
    }
  },
  computed: {
    // 常用功能菜单
    commonMenuList () {
      // this.isInit = true
      return this.store.state.commonMenuList || []
    },
    // tab栏菜单
    sidebarMenuList () {
      this.menuList.forEach((menu, index) => {
        // 默认选中第一个
        if (!this.isInit && index === 0) { this.selectedMenuData = menu }

        menu.children.forEach(subMenu => {
          subMenu.children.forEach(thirdMenu => {
            this.commonMenuList.forEach(commonMenu => {
              if (thirdMenu.id === commonMenu.menuId) {
                thirdMenu.isCommon = true
                // 将常用菜单的id保存到菜单列表上用来删除常用菜单
                thirdMenu.commonMenuId = commonMenu.id
              }
            })
          })
        })
      })
      return this.menuList
    }
  },
  watch: {
    visible (value) {
      this.$nextTick(() => {
        if (value) {
          // document.body.appendChild(this.$el);
          document.body.style.overflow = 'hidden'
        } else {
          // if (this.$el.parentNode) this.$el.parentNode.removeChild(this.$el);
          document.body.style.overflow = ''
        }
      })
    }
  },
  methods: {
    // 切换常用状态
    changeCommonMenu (thirdMenu) {
      if (!thirdMenu.isCommon) {
        this.addCommonMenu(thirdMenu)
      } else {
        this.removeCommonMenu(thirdMenu)
      }
    },

    // 添加常用菜单
    addCommonMenu (thirdMenu) {
      // 校验最大数不可添加
      if (this.commonMenuList.length >= 41) {
        this.$message.error('最多只能添加40个常用菜单')
        return
      }

      this.loading = true

      this.$http.post(CloudUser.addCommonMenu, {
        menuId: thirdMenu.id,
        type: 3 // PC常用菜单
      }).then(res => {
        this.loading = false
        if (res.data.success) {
          this.$message.success('添加成功')
          thirdMenu.commonMenuId = res.data.data
          // 置为常用菜单
          thirdMenu.isCommon = true
          // 修改常用菜单数据
          this.store.commit('changeCommonMenu', [
            ...this.commonMenuList,
            {
              id: thirdMenu.commonMenuId,
              menuId: thirdMenu.id,
              menuName: thirdMenu.name,
              iconUrl: thirdMenu.iconUrl,
              pcUrl: thirdMenu.pcUrl,
              promotionUrl: thirdMenu.promotionUrl,
              ifNewPage: thirdMenu.ifNewPage
            }
          ])

          this.$forceUpdate()
        } else {
          this.$message.warning(res.data.errorMsg)
        }
      })
    },
    // 移除常用菜单
    removeCommonMenu (thirdMenu) {
      this.loading = true
      this.$http.get(CloudUser.deleteCommonMenu, {
        id: thirdMenu.commonMenuId
      }).then(res => {
        this.loading = false
        if (res.data.success) {
          this.$message.success('取消成功')

          // 取消常用菜单标识
          thirdMenu.isCommon = false
          this.store.commit(
            'changeCommonMenu',
            this.commonMenuList.filter(menu => {
              return menu.menuId !== thirdMenu.id
            })
          )

          this.$forceUpdate()
        } else {
          this.$message.warning(res.data.errorMsg)
        }
      })
    }
  },
  async mounted () {
    const res = await this.$http.get(Default.sidebarMenu)
    if (res.data.success) {
      this.menuList = res.data.data
    }

    // if (this.$el.parentNode) this.$el.parentNode.removeChild(this.$el);
    document.body.appendChild(this.$el)
  }
}
</script>
<style lang="scss">
.common-warp {
  .el-drawer__header {
    font-size: 18px;
    color: #303133;
  }
}
</style>
<style lang="scss" scoped>
.app-manage-modal {
  &_cover {
    width: 100%;
    height: 100%;
    background-color: #303133;
    opacity: 0.3;
  }
  &_header {
    border-bottom: 2px #E4E7ED solid;
  }

  &_modal-content {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &_content-menu {
    height: calc(100vh - 160px);
  }
  &_li {
    position: relative;
    width: 75px;
    margin-bottom: 8px;
    &.active {
      .app-manage-modal_span {
        color: #ffffff;
        background: #409EFF;
      }
    }
  }
  &_span {
    padding: 3px 9px;
    color: #303133;
    cursor: pointer;
    border-radius: 12px;
    &:hover {
      background: #ECF5FF;
    }
  }
  &_tab {
    color: #333;
    text-align: center;
    cursor: pointer;
    height: 30px;
  }

  &_line {
    display: none;
    position: absolute;
    bottom: -2px;
    left: 5px;
    height: 2px;
    background-color: #0bb0f6;
    width: 70px;
  }

  &_content {
    display: none;
    padding: 0px 25px;

    &.active {
      display: block;
    }
  }

  &_content-sub {
    width: 125px;
    margin-right: 38px;
  }

  &_sub-title {
    color: #C0C4CC;
    margin-bottom: 10px;
    padding-left: 20px;
  }

  &_sub-third {
    li {
      color: #606266;
      margin-bottom: 10px;
      padding-left: 20px;
      label {
        img {
          position: absolute;
          top: 2px;
          left: 0px;
        }
        .un-select {
          display: none;
        }
        span {
          display: none;
        }
      }

      &:hover {
        .un-select {
          display: inline !important;
        }
      }
    }
  }

  &_modal-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .relative {
    position: relative;
  }
}
</style>
