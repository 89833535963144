<template>
  <router-view/>
</template>

<script>
import { MessageBox } from 'element-ui'

const storageListener = event => {
  const { key, newValue, oldValue } = event
  if (key === 'userInfo' && newValue !== null && newValue !== oldValue) {
    MessageBox({
      title: '提示',
      message: '检测到重新登录账号，请刷新页面...',
      customClass: 'change-login-account-messagebox',
      showClose: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      closeOnHashChange: false
    }).then(() => {
      window.location.href = '/home'
    })
  }
}
export default {
  name: 'App',
  mounted () {
    window.addEventListener('storage', storageListener)
  },
  beforeDestroy () {
    window.removeEventListener('storage', storageListener)
  }
}
</script>

<style>
body {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*在iframe中时将overlay的背景色改为浅色*/
body.body-in-iframe .v-modal {
  background: hsla(0, 0%, 100%, .9);
  opacity: unset;
}
/*切换账号弹窗提示样式 angularjs样式冲突问题*/
.change-login-account-messagebox .el-message-box__title span{
  font-size: 18px;
  line-height: 1;
  color: #303133;
}
.change-login-account-messagebox .el-message-box__content p {
  color: #606266;
  font-size: 14px;
}
.change-login-account-messagebox .el-message-box__btns span{
  color: #fff;
  font-size: 12px;
}
</style>
