<template>
  <div class="vh-100 d-flex flex-column align-items-center justify-content-center">
    <p class="mb-20">
      断网了
    </p>
    <el-button @click="restart">
      点击重试
    </el-button>
  </div>
</template>
<script>

import Store from '../../store'

export default {
  methods: {
    restart () {
      if (window.navigator.onLine) {
        Store.commit('changeNetworkOnline', true)
        this.$router.replace(this.$store.state.network.beforeOfflinePath || '/')
      }
    }
  },
  created () {
  }
}
</script>
