<template>
  <div :class="bem()">
    <div class="clearfix bg-fff pr-20 w-100">
      <div class="float-left ">
        <div class="d-flex align-items-center workbench-group">
          <el-link icon="el-icon-monitor" :underline="false" @click="toWorkbench">
            工作台
          </el-link>
        </div>
      </div>
      <div class="float-right">
        <div class="clearfix d-flex align-items-center right-group">
          <div class="float-left search-group">
            <el-select
              v-model="menuValue"
              remote
              filterable
              clearable
              placeholder="菜单搜索"
              :remote-method="menuSearch"
              @clear="clearFn"
              @change="openMenuUrl"
              value-key="pcUrl"
              no-data-text="暂无符合要求菜单"
              size="small"
              style="width: 100%"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"/>
              <el-option
                v-for="(item) in menuNewList"
                :key="item.pcUrl"
                :value="item"
                :label="item.name"
              >
                <div v-html="showFilterValue(item.name)"/>
              </el-option>
            </el-select>
          </div>
          <!--app下载-->
          <el-popover
            placement="bottom-end"
            title=""
            trigger="hover"
            popper-class="app-download-popover"
          >
            <div slot="reference" class="operate-group">
              APP下载
            </div>
            <div class="app-download-group">
              <div class="d-flex justify-content-between mb-20">
                <div class="app-download-item">
                  <div class="app-download-img">
                    <img :src="yilihuoAppQrCode" alt="">
                  </div>
                  <div class="tool-download-title">
                    海尔易理货APP
                  </div>
                </div>
                <div class="app-download-item">
                  <div class="app-download-img">
                    <img
                      src="../../assets/images/warehouse-qrcode.png"
                      alt=""
                    >
                  </div>
                  <div class="tool-download-title">
                    仓管助手
                  </div>
                </div>
                <div class="app-download-item">
                  <div class="app-download-img">
                    <img src="../../assets/images/cloud-qrcode.png" alt="">
                  </div>
                  <div class="tool-download-title">
                    海尔智家云店APP
                  </div>
                </div>
                <div class="app-download-item">
                  <div class="app-download-img">
                    <img src="../../assets/images/ylh-weixin-qrcode.png" alt="">
                  </div>
                  <div class="tool-download-title">
                    易理货公众号
                  </div>
                </div>
              </div>
              <div class="app-download-divider"/>
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="app-download-img2">
                    <img src="https://jsh-oss-static.jsh.com/prod/image/2024/9/25/e78e960e-1ee5-4cf7-94ef-e6630da224ac.png" alt="">
                  </div>
                  <div>
                    <div class="app-download-title2">
                      易理货桌面端安装包
                    </div>
                    <div class="app-download-btn-wrap">
                      <div class="app-download-btn" @click="download()">
                        win系统下载
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center pr-40">
                  <div class="app-download-img2">
                    <img src="https://jsh-oss-static.jsh.com/prod/image/2024/9/25/a15863f0-5e5b-4c26-9730-620ede508e91.png" alt="">
                  </div>
                  <div>
                    <div class="app-download-title2">
                      智慧云店TV版
                    </div>
                    <div class="app-download-btn-wrap" @click="download(tvYlhApkUrl)">
                      <div class="app-download-btn w-60">
                        下载
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-popover>
          <!--问题提报-->
          <div class="operate-group eQuestion" v-if="isShowQuestion" @click="questionReport">
            易问e答
          </div>
          <!--帮助中心-->
          <div class="operate-group helpGroup" v-if="helpCenterLink" @click="openHelpCenter">
            帮助
          </div>
          <!--消息-->
          <el-badge :value="unreadNumber" :hidden="unreadNumber < 1" class="ml-24">
            <div class="operate-group messageGroup" @click="toPageForHeader">
              通知
            </div>
          </el-badge>
          <!--用户-->
          <el-popover
            placement="bottom-end"
            title=""
            trigger="hover"
            width="240"
            popper-class="user-avatar-popover"
          >
            <div slot="reference" class="avatar-group">
              <el-avatar v-if="!userHeadUrl" :size="24" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"/>
              <el-avatar v-if="userHeadUrl" :size="24" :src="userHeadUrl"/>
              <span class="user-nick text-truncate color-409eff">{{ userName }}</span>
              <i class="el-icon-arrow-down fs-16 color-409eff"/>
            </div>
            <div class="avatar-dropdown">
              <div class="avatar-dropdown-top">
                <div class="avatar-dropdown-top-con">
                  <el-avatar v-if="!userHeadUrl" :size="40" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"/>
                  <el-avatar v-if="userHeadUrl" :size="40" :src="userHeadUrl"/>
                  <div class="ml-8 flex-1 d-flex flex-column align-items-start justify-content-center">
                    <div class="w-100 color-000 text-truncate">
                      {{ userName }}
                    </div>
                    <div class="color-606266 w-100 text-truncate" v-if="memberName">
                      {{ memberName }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="avatar-dropdown-bottom">
                <div class="avatar-dropdown-bottom-item" @click="openCustomerCenterPage('/personalSetting/index')">
                  <el-link class="link-row" :underline="false">
                    安全中心
                  </el-link>
                  <i class="el-icon-arrow-right fs-16"/>
                </div>
                <div class="avatar-dropdown-bottom-item" @click="openCustomerCenterPage('/personalSetting/index')">
                  <el-link class="link-row" :underline="false">
                    修改密码
                  </el-link>
                  <i class="el-icon-arrow-right fs-16"/>
                </div>
                <div class="avatar-dropdown-bottom-item" @click="logout">
                  <el-link class="link-row" :underline="false">
                    退出登录
                  </el-link>
                  <i class="el-icon-arrow-right fs-16"/>
                </div>
              </div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CloudUser, V2Portal, CloudNotice, CloudBase } from '../../apis'
import { getFullAddress, yilihuoAppQrCode } from '../../utils'
import storeMixin from '../../mixins/store'
import bemMixin from '../../mixins/class-name'
import Vue from 'vue'
import { tellRedirectUrl } from '../../router/utils'
import bus from '../../bus'
import { storage, jwtToken, DocWatermark } from '@jsh/helper/utils'

export default {
  mixins: [storeMixin, bemMixin('layout-header')],
  data () {
    return {
      helpCenterLink: '', // 帮助中心链接
      unreadNumber: 0, // 未读数量
      userName: '', // 用户名称
      memberName: '', // memberName名称
      userHeadUrl: '', // 员工头像
      udeskScriptUrl: `${location.origin}/cloud/assets/jsh/business/udesk/udesk.customer.js`,
      memberId: '',
      isShowQuestion: false,
      menuValue: '',
      menuList: [], // 菜单列表
      menuNewList: [], // 菜单列表
      height: 0,
      wm: null,
      yilihuoAppQrCode,
      tvYlhApkUrl: null // 智家云店tv版apk下载地址
    }
  },
  methods: {
    toWorkbench () {
      this.store.commit('changeBreadcrumbList', [])
      this.$router.replace('/home')
    },
    // 打开帮助中心
    openHelpCenter () {
      // window.open(this.helpCenterLink)
      window.open(`${window.location.origin}/help/help-home`)
    },

    getMemberBasicInfoByIdOrSellercode () {
      this.$http.post(CloudUser.getMemberBasicInfoByIdOrSellercode, {
        id: this.memberId
      }).then(res => {
        const { success, data } = res.data
        if (success) {
          if (data) {
            const memberFlag = data.memberFlag
            // P-理货商；R-零售商；S-伞下店 展示入口
            if (['P', 'R', 'S', 'O'].includes(memberFlag)) {
              this.isShowQuestion = true
            }
            // 缓存小微信息
            localStorage.setItem(
              'userGmInfo',
              JSON.stringify({
                gmName: data.gmName,
                gmCode: data.gmCode
              } || {})
            )
          }
        }
      })
    },
    // 问题提报
    questionReport () {
      // path: '/webv2//base/question-list'
      location.href = '/pc-wom/question-list'
    },
    // 打开新页面
    openPage (url) {
      location.href = getFullAddress(url)
    },
    toPageForHeader () {
      // window.open('/webv2/portal/messageList')
      window.open('/msg-center/message-manage/message-list')
    },
    // 打开客户中心页面
    openCustomerCenterPage (url) {
      window.open(process.env.VUE_APP_CUSTOMER_CENTER_HOST + url)
    },
    // 退出
    async logout () {
      const res = await this.$http.get(V2Portal.logout)
      if (res.data.success) {
        localStorage.removeItem('pageType')
        if (process.env.NODE_ENV === 'development') {
          this.$router.push('/login')
        } else {
          window.location.href = `${process.env.VUE_APP_CUSTOMER_CENTER_HOST}/logout?redirect_uri=${location.origin}`
        }
      }
    },
    // GrowingIO脚本
    GrowingIO (
      growingIOUserId = '0000000000',
      growingIOYlhMemberId,
      growingIOYlhUserName,
      growingIOYlhUserPhone,
      growingIOYlhUserNickName,
      growingIOYlhMemberName
    ) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.innerHTML =
        '!function(e,n,s,t,a){var c;e[t]=e[t]||function(){(e[t].q=e[t].q||[]).push(arguments)};var i="gdp";e[i]=e[i]||function(){(e[t].q=e[t].q||[]).push(arguments)},a=a||"vds",e._gio_local_vds=a,e[a]=null!==(c=e[a])&&void 0!==c?c:{},e[a].namespace=t;var o=n.createElement("script"),r=n.getElementsByTagName("script")[0];o.async=!0,o.src="https://unpkg.haier.net/gio-webjs-sdk@4/dist/gdp-full.js",r.parentNode.insertBefore(o,r)}(window,document,0,"gio");' +
        '    gio("init", "92e3bf168f23aef6", "8aa86f72671511ee", {' +
        '      "version":"4.0",' +
        '      "setImp":"false",' +
        '      "setTrackerScheme":"https",' +
        '      "enableHT": true' +
        '    });' +
        '    gio("setUserId", "' +
        growingIOUserId +
        '");' +
        '    gio("people.set", {' +
        '      "ylh_member_id": "' +
        growingIOYlhMemberId +
        '",' +
        '      "ylh_user_name": "' +
        growingIOYlhUserName +
        '",' +
        '      "ylh_user_phone": "' +
        growingIOYlhUserPhone +
        '",' +
        '      "ylh_user_nickname": "' +
        growingIOYlhUserNickName +
        '",' +
        '      "ylh_member_name": "' +
        growingIOYlhMemberName +
        '"' +
        '    });'
      document.head.appendChild(script)
    },
    // 获取子应用路由地址状态
    getPathStatus (url) {
      // 子应用路由匹配列表
      const microAppPathList = Vue.prototype.MicroApps.map(config => config.router)

      for (let i = 0; i < microAppPathList.length; i++) {
        const regx = new RegExp(`^${microAppPathList[i]}`, 'i')
        if (regx.test(url)) {
          return microAppPathList[i]
        }
      }
      return ''
    },
    // 判断是否含有二期项目路由前缀
    hasAngularJsPath (childRouter) {
      let isAngularjs = false
      let angularPrefix = '';
      ['/cloud/', '/ylh/', '/portal/'].forEach((prefix) => {
        if (location.pathname.indexOf(prefix) > -1) {
          isAngularjs = true
          angularPrefix = prefix
        }
      })
      // 当前为二期项目
      if (isAngularjs) {
        // 非当前项目
        return angularPrefix !== (childRouter + '/')
      }
      // 非二期项目但是要跳转二期项目
      return ['/cloud', '/ylh', '/portal'].includes(childRouter)
    },
    // 远程菜单搜索
    menuSearch (value) {
      const that = this
      that.menuValue = value
      if (that.menuValue) {
        that.menuNewList = []
        that.menuList.forEach(menu => {
          menu.children.forEach(subMenu => {
            subMenu.children.forEach(thirdMenu => {
              if (thirdMenu.name.includes(that.menuValue.trim())) {
                const menuUrl = {
                  ifNewPage: thirdMenu.ifNewPage,
                  pcUrl: thirdMenu.pcUrl ? thirdMenu.pcUrl : `/internal/menu?id=${thirdMenu.id}`,
                  menuId: thirdMenu.id,
                  name: thirdMenu.name,
                  id: thirdMenu.id
                }
                that.menuNewList.push(menuUrl)
              }
              if (thirdMenu.children && thirdMenu.children.length !== 0) {
                that.recursive(thirdMenu.children, value)
              }
            })
          })
        })
      } else {
        this.menuNewList = []
      }
    },
    // 递归处理列表
    recursive (list, value) {
      const that = this
      list.forEach((item, index) => {
        // 存在pcUrl加入搜索列表
        if (item.name.includes(value)) {
          const menuUrl = {
            ifNewPage: item.ifNewPage,
            pcUrl: item.pcUrl ? item.pcUrl : `/internal/menu?id=${item.id}`,
            menuId: item.id,
            name: item.name,
            id: item.id
          }
          that.menuNewList.push(menuUrl)
        }
        if (item.children && item.children.length !== 0) {
          that.recursive(item.children, value)
        }
      })
    },
    clearFn () {
      this.menuNewList = []
      this.menuValue = ''
    },
    // 搜索菜单跳转
    openMenuUrl (thirdMenu) {
      if (thirdMenu) {
        const childRouter = this.getPathStatus(thirdMenu.pcUrl)
        if (childRouter) {
          // 子应用工程
          if (thirdMenu.pcUrl) {
            const url = tellRedirectUrl(thirdMenu.pcUrl)
            window.open(url)
            this.store.commit('changeActiveMenuId', thirdMenu.id)
          }
        } else {
          window.open(thirdMenu.pcUrl)
        }
      }
    },
    showFilterValue (item) {
      return item.replace(
        this.menuValue,
        '<font color="#409EFF">' + this.menuValue + '</font>'
      )
    },
    // 初始化水印
    initWatermark () {
      const tokenData = jwtToken.decode(this.$cookie.get('token'))
      this.wm = new DocWatermark({
        content: `${tokenData.seller_code ? (tokenData.seller_code === tokenData.user_name ? tokenData.seller_code : `${tokenData.seller_code}-${tokenData.user_name}`) : tokenData.user_name}`,
        fullFixed: true,
        height: 150,
        width: 150,
        zIndex: 9999,
        angle: 15
      })
    },
    async download (url) {
      if (!url) {
        if (window.gio) { window.gio('track', 'j9y64qaR_017911') }
        await fetch(`${CloudBase.getAppVersionAndModule}?systemVersion=ylh_pc`, { method: 'GET', credentials: 'omit' })
          .then(_ => _.json())
          .then(data => {
            if (data.success) {
              url = (data.data || [])
                .find(_ => _.moduleCode === 'win32')
                ?.downloadUrl || ''
            } else {
              this.$message.error(data.errorMsg || '网络异常，请稍后再试~')
            }
          })
          .catch(() => {
            this.$message.error('网络异常，请稍后再试~')
          })

        if (!url) {
          return
        }
      }

      window.open(url, '_blank', 'noopener noreferrer')
    }
  },
  created () {
    const userInfo = localStorage.getItem('userInfo')
    const sessionId = localStorage.getItem('sessionId')
    const tokenIdent = localStorage.getItem('tokenIdent')
    this.height = (document.body.clientWidth - 120) * 0.42 + 'px'
    console.log('userInfo', userInfo)
    if (userInfo) {
      try {
        this.memberId = JSON.parse(userInfo).member.id
      } catch (e) {
        console.log('userInfo', userInfo)
      }
      this.$http.get(CloudUser.getUserInfoById, {
        userId: JSON.parse(userInfo).id
      }).then(res => {
        if (res.data.success) {
          localStorage.setItem('accountId', res.data.data.accountId)
        }
      })
      this.initWatermark()
    }
    // 如果用户信息不存在或者用户信息不一致，则获取一次存入本地
    if (!userInfo || tokenIdent !== this.$cookie.get('token')?.substr(-16)) {
      // 初始化获取me接口
      this.$http.get(V2Portal.me).then(res => {
        if (res.data.success && res.data.data.ylh_user_info) {
          // 存入本地存储
          localStorage.setItem(
            'userInfo',
            JSON.stringify(res.data.data.ylh_user_info || {})
          )
          this.initWatermark()
          try {
            storage.setItem('tokenData', jwtToken.decode(this.$cookie.get('token')))
          } catch (e) {
          }
          // bus.$emit('userInfo', JSON.stringify(res.data.data.ylh_user_info))
          localStorage.setItem('sessionId', res.data.data.sessionid)
          localStorage.setItem(
            'shopInfo',
            JSON.stringify(res.data.data.ylh_shop_info)
          )
          localStorage.setItem(
            'tokenIdent',
            this.$cookie.get('token').substr(-16)
          )

          this.helpCenterLink = `http://help.yilihuo.com?JSESSIONID=${res.data
            .data.sessionid || ''}&type=1`
          this.userName = res.data.data.ylh_user_info.userName
          this.memberName = res.data.data.ylh_user_info.member.memberName
          const infostr = localStorage.getItem('userInfo')
          const info = JSON.parse(infostr)
          this.$http.get(CloudUser.getUserInfoById, {
            userId: info.id
          }).then(res => {
            if (res.data.success) {
              localStorage.setItem('accountId', res.data.data.accountId)
            }
          })
          this.GrowingIO(
            info.id,
            info.member.id,
            info.userName,
            info.userPhone,
            info.userNick,
            info.member.memberName
          )
        }
      })
    } else {
      this.helpCenterLink = `http://help.yilihuo.com?JSESSIONID=${sessionId ||
      ''}&type=1`
      this.userName = JSON.parse(userInfo).userName
      this.memberName = JSON.parse(userInfo).member.memberName
      const infostr = localStorage.getItem('userInfo')
      const info = JSON.parse(infostr)
      this.GrowingIO(
        info.id,
        info.member.id,
        info.userName,
        info.userPhone,
        info.userNick,
        info.member.memberName
      )
    }

    // 初始化获取未读数量
    this.$http.post(CloudNotice.getshowtypeunreadcount).then(res => {
      if (res.data.success) {
        this.unreadNumber = res.data.data.unReadCount
      }
    })

    // 初始化获取员工头像
    this.$http.get(CloudUser.userInfoIndex).then(res => {
      if (res.data.success && res.data.data) {
        if (res.data.data.portraitPath) {
          this.userHeadUrl = res.data.data.portraitPath
        } else {
          this.userHeadUrl = ''
        }
      }
    })
    this.getMemberBasicInfoByIdOrSellercode()

    // 初始化获取用户角色和商家类型:用于gio上报
    this.$http.get(CloudUser.getMemberFlagAndRole).then(res => {
      if (res.data.success) {
        localStorage.setItem('memberFlagAndRole', JSON.stringify({
          memberFlagName: res.data.data.memberFlagName,
          userRoleList: res.data.data.userRoleList?.map(_ => _.roleName).join(',')
        }))
      }
    })

    // 获取智家云店tv版apk下载地址
    this.$http
      .get(CloudBase.getAppVersion, {
        systemVersion: 'tv_ylh'
      })
      .then(res => {
        if (res.data.success) {
          this.tvYlhApkUrl = res.data.data[0].downloadUrl
        }
      })
  },
  mounted () {
    const that = this
    bus.$on('init-menu-list', () => {
      that.menuList = JSON.parse(localStorage.getItem('menuList')) || []
    })
  },
  beforeDestroy () {
    // 销毁水印 一定要销毁
    if (this.wm) {
      this.wm.destroy()
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  background-color: #fff;
  z-index: 18;
  padding-left: 120px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background: rgb(255, 255, 255);

  &::v-deep .el-input__inner {
    border-radius: 16px;
  }

  .workbench-group {
    box-sizing: border-box;
    padding-left: 28px;
    height: 70px;
    display: flex;
    align-items: center;
  }

  //菜单搜索
  .search-group {
    width: 260px;
    height: 32px;
    box-sizing: border-box;
    margin-right: 36px;

    &::v-deep .el-input__inner {
      border-radius: 4px;
      border: 1px solid #dcdfe6;
    }
  }

  .operate-group {
    background: url('https://jsh-oss-static.jsh.com/prod/image/2024/9/10/da162ba6-2131-4a2b-9657-5f6504067177.png') no-repeat;
    background-size: 24px 24px;
    background-position: top center;
    color: #606266;
    font-size: 12px;
    height: 40px;
    line-height: 14px;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }

  .operate-group:hover {
    background: url('https://jsh-oss-static.jsh.com/prod/image/2024/9/10/d13b625b-5abb-4302-a309-4aa986a97f6d.png') no-repeat;
    background-size: 24px 24px;
    background-position: top center;
    color: #409EFF;
    font-size: 12px;
    height: 40px;
    line-height: 14px;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }

  .link-cloumn {
    display: flex;
    flex-direction: column;

    &::v-deep .el-link--inner {
      font-size: 10px;
      margin-top: 2px;
      color: #606266;
    }
  }

  .eQuestion {
    background: url('https://jsh-oss-static.jsh.com/prod/image/2024/9/10/eca734d5-e3d6-4a4c-a037-3aefefb264db.png') no-repeat;
    background-size: 24px 24px;
    background-position: top center;
    margin-left: 24px;
  }

  .eQuestion:hover {
    background: url('https://jsh-oss-static.jsh.com/prod/image/2024/9/10/eca734d5-e3d6-4a4c-a037-3aefefb264db.png') no-repeat;
    background-size: 24px 24px;
    background-position: top center;
    color: #606266;
  }

  .helpGroup {
    background: url('https://jsh-oss-static.jsh.com/prod/image/2024/9/10/091167b6-db50-4406-89ca-c26f9b5c673f.png') no-repeat;
    background-size: 24px 24px;
    background-position: top center;
    margin-left: 24px;
  }

  .helpGroup:hover {
    background: url('https://jsh-oss-static.jsh.com/prod/image/2024/9/10/091167b6-db50-4406-89ca-c26f9b5c673f.png') no-repeat;
    background-size: 24px 24px;
    background-position: top center;
    color: #606266;
  }

  .messageGroup {
    background: url('https://jsh-oss-static.jsh.com/prod/image/2024/9/10/fa10a449-45e1-447e-8d2e-d100be368274.png') no-repeat;
    background-size: 24px 24px;
    background-position: top center;
    position: relative;

    .message-count {
      position: absolute;
      min-width: 16px;
      height: 16px;
      padding: 0 4px;
      background: #F56C6C;
      border: 1px solid #fff;
      top: -8px;
      left: 24px;
      transform: translateX(-35%);
      color: #fff;
      font-size: 12px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      line-height: 14px;
    }
  }

  .messageGroup:hover {
    background: url('https://jsh-oss-static.jsh.com/prod/image/2024/9/10/fa10a449-45e1-447e-8d2e-d100be368274.png') no-repeat;
    background-size: 24px 24px;
    background-position: top center;
    color: #606266;
  }

  .right-group {
    box-sizing: border-box;
    height: 70px;
  }

  .avatar-group {
    margin-left: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .user-nick {
      margin-left: 4px;
      max-width: 200px;
    }

    i {
      margin-left: 4px;
    }
  }
}

.color-409eff {
  color: #409eff;
}
</style>
<style lang="scss">
.app-download-popover {
  width: 632px;
  height: 325px;
  border-radius: 8px !important;
  border: none !important;
  padding: 0!important;

  .app-download-group {
    padding: 30px 40px;

    .app-download-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      //flex: 1;

      .tool-download-title {
        padding-top: 7px;
        color: #303133;
        white-space: nowrap;
      }

      .app-download-img {
        width: 108px;
        height: 108px;
        box-sizing: border-box;
        padding: 6px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .app-download-item:first-child {

      .app-download-img {
        padding: 0;
      }
    }
    .app-download-divider {
      height: 1px;
      background-color: #e4e7ed;
      margin-bottom: 25px;
    }
    .app-download-img2 {
      width: 80px;
      height: 80px;
      margin-right: 16px;
      img {
        width: 100%;
        display: block;
        height: 100%;
      }
    }
    .app-download-title2 {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0;
      margin-bottom: 8px;
      color: #000;
    }
    .app-download-btn-wrap {
      display: flex;
      column-gap: 12px;
    }
    .app-download-btn {
      cursor: pointer;
      border: 1px solid rgb(220, 223, 230);
      border-radius: 4px;
      width: 110px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      letter-spacing: 0;
      color: #303133;
      text-align: center;
      &:hover {
        color: #409eff;
        border-color: #409eff;
      }
    }
    .pr-40 {
      padding-right: 40px;
    }
  }
}

.user-avatar-popover {
  border-radius: 8px !important;
  border: none !important;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 0 !important;

  .avatar-dropdown-top {
    width: 100%;
    height: 79px;
    box-sizing: border-box;
    padding: 2px 2px 0;

    &-con {
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0 0;
      background: linear-gradient(180.00deg, rgb(237, 244, 255), rgb(253, 254, 255) 100%);
      box-sizing: border-box;
      padding: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .avatar-dropdown-bottom {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column;

    &-item {
      margin-top: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #303133;
      cursor: pointer;

      .link-row {
        flex: 1;
        justify-content: left;
      }
    }
  }
}
</style>
