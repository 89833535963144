import Vue from 'vue'

/**
 * 输入框限制输入数字
 *
 * 用于input或包含input的组件上，用来限制输入数字
 *
 * 例子：
 *
 * <van-field v-model="test" v-number-input></van-field> 限制只能是整数
 * <van-field v-model="test" v-number-input="{integer: 10}"></van-field> 限制10位整数
 * <van-field v-model="test" v-number-input.float="{decimal: 2}"></van-field> 支持浮点数，整数不限制，限制2位小数
 * <van-field v-model="test" v-number-input.float="{integer: 10, decimal: 2}"></van-field> 支持浮点数，限制10位整数，2位小数
 */

function onInput (el, ele, binding, vnode) {
  function handle () {
    // debugger
    let val = ele.value
    // modifiers为修饰符对象，传入了float，则其float属性为true
    if (binding.modifiers.float) {
      // 清除"数字"和"."以外的字符
      val = val.replace(/[^\d.]/g, '')
      // 只保留第一个, 清除多余的
      val = val.replace(/\.{2,}/g, '.')
      // 第一个字符如果是.号，则补充前缀0
      val = val.replace(/^\./g, '0.')
      const pointKeep = binding.value && binding.value.decimal ? binding.value.decimal : 0
      const str = `^(\\d+)\\.(\\d{${pointKeep}}).*$`
      const reg = new RegExp(str)
      if (pointKeep === 0) {
        // 不需要小数点
        val = val.replace(reg, '$1')
      } else {
        // 通过正则保留小数点后指定的位数
        val = val.replace(reg, '$1.$2')
      }
    } else {
      val = ele.value.replace(/[^\d]/g, '')
    }
    // 整数位限制
    if (binding.value && binding.value.integer) {
      val =
        val.indexOf('.') > 0
          ? `${val.split('.')[0].substring(0, binding.value.integer)}.${val.split('.')[1]}`
          : val.substring(0, binding.value.integer)
    }
    ele.value = val

    // 手动更新双向绑定的值(避免双向绑定失效)
    if (vnode.componentInstance) {
      // 绑定的是vue组件的情况
      vnode.componentInstance.$emit('input', ele.value)
    } else {
      // 绑定的是原生dom的情况
      const { directives } = vnode.data
      if (directives && directives.length > 0) {
        const directive = directives.find(item => {
          return item.rawName === 'v-model'
        })
        if (directive) {
          const attrs = directive.expression.split('.')
          if (attrs.length === 1) {
            vnode.context[attrs[0]] = ele.value
          } else if (attrs.length > 1) {
            let temp = vnode.context[attrs[0]]
            for (let i = 1; i < attrs.length - 1; i += 1) {
              temp = temp[attrs[i]]
            }
            temp[attrs[attrs.length - 1]] = ele.value
          }
        }
      }
    }
  }

  return handle
}

Vue.directive('number-input', {
  bind (el, binding, vnode) {
    const ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
    // @ts-ignore
    ele.addEventListener('input', onInput(el, ele, binding, vnode), false)
  }
})
