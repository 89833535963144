/**
 * 易理货ECM微服务工程接口
 */

const PREFIX = ''

// 接口列表
export const Default = {
  // 登录
  LOGIN: `${PREFIX}/login/rest/loginajax`,
  // 左侧菜单列表
  sidebarMenu: `${PREFIX}/rest/auth/my-menu`,
  // 权限码列表
  searchPermissionList: `${PREFIX}/rest/auth/my-permission`
}
