import Vue from 'vue'
import App from './App.vue'
import router, { addChildRouter } from './router'
import store from './store'
import { i18n } from './plugins'
import './mixins'
import './filters'
import './directives'
import './components'
import './utils/proxy'
import './assets/styles/util.scss'
import { initMicroAppProps, initMicroAppConfig } from './micro-config'
import VueCookie from 'vue-cookie'
import axios from 'axios'
import { CloudBase, V2Portal } from './apis'
import { initMainSentry } from './sentry'
import RedirectHttpRequest from './components/redirect-http-request'
import { initReport } from '@jsh/report-page-time'
import { getYLHTokenAnyway, removeYLHToken, setYLHToken } from './utils/auth'
import qs from 'qs'
import { tellIsIframe } from './utils'
import WujieVue from 'wujie-vue2'

// 初始化执行一次
initReport('PC', process.env.VUE_APP_RUN_ENV, 'RELEASE_VERSION_FOR_WEBPACK')

Vue.use(VueCookie).use(WujieVue)

Vue.config.productionTip = false

// 打开 dev 环境 Http 请求重定向功能
if (process.env.VUE_APP_RUN_ENV === 'dev') {
  let instance = null
  instance = new Vue({
    el: document.createElement('div'),
    render (h) {
      return h(RedirectHttpRequest, { props: { vuePrototype: Vue.prototype } })
    }
  })
  document.body.appendChild(instance.$el)
}
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true
}

Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(err)
  } else {
    window.__bl.error(err)
  }
}

if (process.env.NODE_ENV === 'production') {
  initMainSentry()
}

const routeQuery = qs.parse(window.location.search, { ignoreQueryPrefix: true })

const _axios = axios.create()

// 易理货aid转换易理货token
const initYlhToken = async () => {
  // cookie 中的 token 是登录时服务器直接附带的，没有时意味着没走正经 login 接口登录
  // 没有 cookie.token，意味着是在 iframe 中直接走了 location.href 进入的当前页面，此时复用上次保存的 token
  let token = getYLHTokenAnyway()

  const { _YIB_aid: aid = '' } = routeQuery
  if (aid && tellIsIframe()) {
    // 使用 aid 重新获取 token 并保存
    removeYLHToken()

    token = await _axios
      .get(CloudBase.getTokenByAid, { params: { aid } })
      .then(res => {
        const { data } = res
        return data.success ? (data.data || '') : ''
      })
      .catch(() => '')
  }

  if (token) {
    setYLHToken(token)
    return true
  }
  return false
}

// 检测目标用户和当前登录用户是否一致
const checkSameUser = () => {
  const { id } = JSON.parse(localStorage.getItem('userInfo') || 'null') || {}
  const { _YIB_loginUserId: userId } = routeQuery
  return !userId || String(userId) === String(id)
}

const initVue = async (list) => {
  if (!await initYlhToken() && tellIsIframe()) {
    // 没有 token，并且处于 iframe 中时，直接进入 /403
    await router.replace('/403')
  }

  if (!checkSameUser()) {
    const done = () => {
      window.location.replace(`${process.env.VUE_APP_CUSTOMER_CENTER_HOST}/logout?redirect_uri=${location.origin}`)
    }

    await _axios.get(V2Portal.logout)
      .then(async ({ data }) => {
        if (data.success) {
          if (process.env.NODE_ENV === 'development') {
            await router.replace('/login')
          } else {
            done()
          }
        }
      })
      .catch(() => {
        Vue.prototype.$cookie.delete('token')
        done()
      })
  }

  const { _YIB_iframeSource: iframeSource } = routeQuery
  const iframeSourceKey = 'iframe_source'
  if (iframeSource) {
    window.localStorage.setItem(iframeSourceKey, iframeSource)
  } else {
    window.localStorage.removeItem(iframeSourceKey)
  }

  const MicroApps = list.map(app => initMicroAppConfig(app))
  initMicroAppProps(MicroApps)
  addChildRouter(MicroApps)
  Vue.prototype.MicroApps = MicroApps
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

_axios.get(CloudBase.getChildAppConfigList).then(res => {
  initVue(res.data.success ? res.data.data : [])
}).catch(err => {
  console.error(err)
  initVue([])
})
