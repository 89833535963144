import { Vuex } from './vuex'
import state from '../src/store/state'
import mutations from '../src/store/mutations'
import getters from '../src/store/getters'

export default new Vuex({
  state,
  mutations,
  getters,
  actions: {},
  modules: {}
})
