/**
 * 加载第三方远程脚本
 *
 * 属性：
 * src    string    脚本地址
 *
 * 事件
 * load   加载后的回调
 *
 * 示例：
 * <remote-js src="//pv.sohu.com/cityjson?ie=utf-8"></remote-js>
 *
 * 全局组件，不需要单独引入
 */

export default {
  name: 'RemoteJs',
  props: {
    src: {
      type: String,
      required: true
    }
  },
  render (createElement) {
    return createElement('script', {
      attrs: {
        type: 'text/javascript',
        src: this.src
      },
      on: {
        load: () => {
          this.$emit('load')
        }
      }
    })
  }
}
