import Vue from 'vue'

Vue.mixin({
  beforeRouteLeave: function (to, from, next) {
    to.meta.rank = to.meta.rank ? to.meta.rank : 0.1
    from.meta.rank = from.meta.rank ? from.meta.rank : 0.1
    if (
      from &&
      from.meta.rank &&
      to.meta.rank &&
      from.meta.rank > to.meta.rank
    ) {
      // 此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
      if (this.$vnode && this.$vnode.data.keepAlive) {
        if (
          this.$vnode.parent &&
          this.$vnode.parent.componentInstance &&
          this.$vnode.parent.componentInstance.cache
        ) {
          if (this.$vnode.componentOptions) {
            var key =
              this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid +
                (this.$vnode.componentOptions.tag
                  ? `::${this.$vnode.componentOptions.tag}`
                  : '')
                : this.$vnode.key
            var cache = this.$vnode.parent.componentInstance.cache
            var keys = this.$vnode.parent.componentInstance.keys
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key)
                if (index > -1) {
                  keys.splice(index, 1)
                }
              }
              delete cache[key]
            }
          }
        }
      }
      this.$destroy()
    }
    next()
  }
})
