
export class Vuex {
  constructor (options) {
    this.state = {}
    const state = this.state
    this.options = options
    this.getters = {}
    Object.keys(options.state).forEach(k => {
      this.state[k] = options.state[k]
    })
    Object.keys(options.getters).forEach(key => {
      Object.defineProperty(this.getters, key, {
        get: () => {
          return options.getters[key](state)
        }
      })
    })
  }

  commit (mutation, payload) {
    this.options.mutations[mutation](this.state, payload)
  }
}
