/**
 * 易理货base微服务工程接口
 */

const PREFIX = `/ylh-cloud-service-order${process.env.VUE_APP_SERVER_SUFFIX}`

// 接口列表
export const CloudOrder = {
  // 判断会员库存是否设置为简流程
  searchSimpleProcessStatus: `${PREFIX}/api/page/order/management/get-order-flow-setting-by-memberid`,
  // 首页-待处理业务-分销订单-待审核订单
  pendingAuditOrder: `${PREFIX}/api/page/order/management/get-order-status-count-and-amount`,
  // 首页-待处理业务-销售订单-数据
  pendingSaleOrderData: `${PREFIX}/api/page/retailorder/management/count-order-list-and-collection`,
  // 首页-分销业务-交易数量
  distributionBusinessTradeNumber: `${PREFIX}/api/page/order/management/get-order-count-and-amount`,
  // 首页-零售业务-全部门店
  retailBusinessSiteData: `${PREFIX}/api/page/order/management/get-order-info-count`,
  // 首页-分销业务-当月汇总
  distributionBusinessMonthSum: `${PREFIX}/api/page/actuals-sales/search-target-and-actual-sales-details-amount`,
  // 首页查询奖励目标实际
  searchTargetAndActualSalesDetailsAmountV2: `${PREFIX}/api/page/actuals-sales/search-target-and-actual-sales-details-amount-v2`
}
