import Vue from 'vue'
import { storage } from '@jsh/helper/utils'
import jsonpPlugin from 'jsonp'
import { getToken, getYLHToken } from '../utils/auth'
import { tellIsIframe } from '../utils'

// 页面禁止引入router文件

// 只有以下路径的子应用在 iframe 中的请求才会被自动附加上 ylh token，防止第三方接入易理货时出现未知问题
const tokenWhiteChildList = ['/webv2/']

/**
 * 主应用拦截子应用请求
 * @param config
 */
const interceptReq = (config) => {
  // 实现dev环境Http请求重定向功能
  const currentSuffix = process.env.VUE_APP_SERVER_SUFFIX.slice(1)
  const redirectList = (Vue.prototype.$redirectHttpRequestList || []).filter(_ => _.suffix !== currentSuffix)
  if (
    process.env.VUE_APP_RUN_ENV === 'dev' &&
    redirectList.length
  ) {
    redirectList.forEach(_ => {
      config.url = config.url.replace(`${_.prefix}-${currentSuffix}`, `${_.prefix}-${_.suffix}`)
    })
  }

  if (!config.headers) {
    config.headers = {}
  }

  if (config.headers.white) {
    config.headers.white = null
    delete config.headers.white

    return false
  }

  const { pathname } = window.location
  let token = getYLHToken()
  // 本地运行登录后清空token 会取不到token
  if (process.env.NODE_ENV === 'development' && !token) {
    token = getToken()
  }
  // 给子应用的接口设置token
  if (
    !config.headers.Authorization &&
    token &&
    tellIsIframe() &&
    tokenWhiteChildList.some(_ => pathname.startsWith(_))
  ) {
    config.headers.Authorization = 'Bearer ' + token
  }

  // 如果需要终止程序时，只需要返回config即可
  // 只在生产环境下拦截，本地不拦截
  if (process.env.NODE_ENV !== 'production') {
    return false
  }

  config.headers['jsh-page-name'] = window.location.href.substr(0, 100) + ':' + Date.now() + '' + Math.random()
}

/**
 * 主应用拦截子应用请求拒绝事件
 * @param error
 */
const interceptReqReject = (error) => {
  console.error(error)
  // 如果需要终止程序时，只需要返回Promise.reject(error)即可
  // 只在生产环境下拦截，本地不拦截
  if (process.env.NODE_ENV !== 'production') { return false }
}

/**
 * 当以下接口返回 900401 或者 900403 时不再退出系统
 * TODO：这里是因为浏览器第三方cookie限制，导致老接口（比如下面这些）无法通过headers接收token，此处就为了跳过这个逻辑
 * @type {*[]}
 */
const logoutWhiteList = tellIsIframe()
  ? [
    '/rest/auth/me.ajax',
    '/rest/auth/my-menu',
    '/rest/auth/my-permission'
  ]
  : []

/**
 * 主应用拦截子应用响应
 * @param response
 */
const interceptRes = (response) => {
  // 如果需要终止程序时，只需要返回response即可
  // 只在生产环境下拦截，本地不拦截
  if (process.env.NODE_ENV !== 'production') { return false }
  if (response && response.config && response.headers) {
    console.log('HTTP:', {
      url: response.config.url,
      'jsh-request-id': response.headers['jsh-request-id']
    })
  }

  // 登录过期退出系统
  if (
    response &&
    response.data &&
    (response.data.code === 900401 || response.data.code === 900403) &&
    !logoutWhiteList.some(_ => response.config.url.startsWith(_))
  ) {
    logout()
    return response
  }

  // 发版时间，重定向到升级页面
  if (
    response &&
    response.data &&
    (response.data.code === 423)
  ) {
    storage.setItem('updateEndTime', response.data.data.endDateTime)
    window.location.href = '/update'
    return response
  }
}
/**
 * 主应用拦截子应用响应拒绝事件
 * @param error
 */
const interceptResReject = (error) => {
  console.error(error)
  // 如果需要终止程序时，只需要返回Promise.reject(error)即可
  // 只在生产环境下拦截，本地不拦截
  if (process.env.NODE_ENV !== 'production') { return false }
}

// 退出
const logout = () => {
  window.location.href = `${process.env.VUE_APP_CUSTOMER_CENTER_HOST}/logout?redirect_uri=${location.origin}`
}

// jsonp 请求
const jsonp = (url, opts, fn) => {
  jsonpPlugin(url, opts, fn)
}

export {
  interceptReq,
  interceptReqReject,
  interceptRes,
  interceptResReject,
  logout,
  jsonp
}
