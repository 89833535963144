/**
 * 易理货ECM微服务工程接口
 */

const PREFIX = '/v2/portal'

// 接口列表
export const V2Portal = {
  // me接口
  me: `${PREFIX}/rest/auth/me.ajax`,
  // 退出
  logout: `${PREFIX}/rest/auth/logout`,
  // 客户中心单点登录后的易理货登录接口
  customerCenterLogin: `${PREFIX}/rest/customerCenter/callbackCode`,
  // 重定向到客户中心接口
  redirectToCC: `${PREFIX}/rest/customerCenter/login-redirect-code`
}
