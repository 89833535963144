import _ from 'lodash'

const getClassName = (prefix, modifiers) => {
  let className = ''
  if (typeof modifiers === 'string') {
    className = ` ${prefix}--${modifiers}`
  }
  if (Array.isArray(modifiers)) {
    modifiers.forEach(modifier => {
      if (!modifier) { return }
      if (_.isPlainObject(modifier)) {
        Object.keys(modifier).forEach((key) => {
          if (modifier[key]) {
            className += ` ${prefix}--${key}`
          }
        })
      }
      if (typeof modifier === 'string') { className += ` ${prefix}--${modifier}` }
    })
  }
  if (_.isPlainObject(modifiers)) {
    Object.keys(modifiers).forEach((key) => {
      if (modifiers[key]) {
        className += ` ${prefix}--${key}`
      }
    })
  }

  return className
}
/**
 * 混入初始化
 * @param componentNameSpace 组件命名空间
 * @return {{methods: {bem(*, *)}}}
 */
const bemMixin = (componentNameSpace) => {
  return {
    methods: {
      bem (element, modifiers = []) {
        let className
        // 返回根节点
        if (arguments.length === 0) { className = `${componentNameSpace}` }
        if (typeof element !== 'string') {
          className = `${componentNameSpace}` + getClassName(`${componentNameSpace}`, element)
        } else {
          // 元素节点
          className = `${componentNameSpace}_${element}` + getClassName(`${componentNameSpace}_${element}`, modifiers)
        }

        return className
      }
    }
  }
}

export default bemMixin
