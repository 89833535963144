import router from './router'
import store from './store'
import { registerMicroApps, addGlobalUncaughtErrorHandler, initGlobalState } from 'qiankun'
import Bus from './bus'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import * as httpHelper from './plugins/axios-helper'
import Vue from 'vue'

NProgress.configure({ showSpinner: false })

// 错误捕获
addGlobalUncaughtErrorHandler(event => {
  console.warn(event)
})

const getActiveRule = (prefix) => (location) => location.pathname.startsWith(prefix)

const isMatchRoute = (url, route) => url.startsWith(route + (route.endsWith('/') ? '' : '/'))

// 初始化子应用属性
const initMicroAppProps = (MicroApps) => {
  MicroApps.forEach((app) => {
    if (!app.props) { app.props = {} }
    app.props.mainRouter = {
      push (path) {
        if (typeof path !== 'string') {
          throw new Error(
            'The parameter path is a string type for the mainRouter.push function, such as "/order/list"'
          )
        }
        router.push(path)
      },
      replace (path) {
        if (typeof path !== 'string') {
          throw new Error(
            'The parameter path is a string type for the mainRouter.replace function, such as "/order/list"'
          )
        }
        router.replace(path)
      }
    }
    app.props.mainBus = Bus
    app.props.mainHelper = { ...httpHelper }
    app.props.mainStore = store
  })

  registerMicroApps(MicroApps, {
    beforeLoad (app) {
      NProgress.start()
    },
    beforeUnmount: () => {
      // 重置掉子应用设置的 sentry信息
      if (window?.$sentry_config?.sentry_dsn) {
        window.$sentry_config.sentry_release = null
        window.$sentry_config.sentry_dsn = null
      }
    },
    afterMount (app) {
      NProgress.done()
    }
  })
}

// 初始化子应用配置
const initMicroAppConfig = (remoteDate) => {
  return {
    name: remoteDate.name,
    entry: `${remoteDate.host}/${remoteDate.path}/?_t=${Date.now()}`,
    container: '#microApp',
    router: `/${remoteDate.path}`,
    activeRule: remoteDate.mode === 'history' ? `/${remoteDate.path}` : getActiveRule(`/${remoteDate.path}`),
    sentry: remoteDate.sentry
  }
}

// 获取当前的子应用
const getCurrentMicroApp = () => {
  let current = {}
  Vue.prototype.MicroApps.forEach(app => {
    if (isMatchRoute(location.pathname, app.router)) {
      current = app
    }
  })
  return current
}

const startsWithMicroAppPath = (url) => {
  for (let i = 0; i < Vue.prototype.MicroApps.length; i++) {
    const app = Vue.prototype.MicroApps[i]
    if (isMatchRoute(url, app.router)) {
      return true
    }
  }
}

// 监听子应用渲染，动态切换为子应用的Sentry
const actions = initGlobalState({ Vue: '', release: '', router: null })
actions.onGlobalStateChange(state => {})

export {
  initMicroAppProps,
  initMicroAppConfig,
  getCurrentMicroApp,
  startsWithMicroAppPath
}
