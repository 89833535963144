import { scriptLoader } from '@jsh/helper/utils'
import { CloudUser } from '../apis'
import axios from 'axios'
import { tellIsIframe } from '../utils'

// TODO：iframe 内暂不展示易问易答悬浮窗功能
if (!tellIsIframe()) {
  const _axios = axios.create()

  // FIXME：由于hwork开发者平台生产发布环境未上线，所以生产环境的文件暂时部署在pre上，所以pre环境的 @1 是pre的，@2 就是生产的 [微笑]
  const scriptUrl = process.env.VUE_APP_WOM_PC_SDK || {
    dev: 'https://unpkg-test.haier.net/app/jsh-wom-pc-sdk@latest/jsh-wom-pc-sdk.js',
    pre: 'https://unpkg-pre.haier.net/app/jsh-wom-pc-sdk@1/jsh-wom-pc-sdk.js',
    prod: 'https://unpkg-pre.haier.net/app/jsh-wom-pc-sdk@2.0.1/jsh-wom-pc-sdk.js'
  }[process.env.VUE_APP_RUN_ENV]

  let tokenRequester = null
  const getToken = async () => {
    const result = await _axios
      .get(CloudUser.getMgWomToken)
      .catch(() => {})
      .finally(() => {
        tokenRequester = null
      })
    if (result?.data?.success && result?.data?.data?.access_token) {
      return result.data.data.access_token
    }
    return null
  }

  scriptLoader.check({
    sign: 'JshWomPcSDK',
    scripts: [scriptUrl]
  }).done(() => {
    // 防止子应用引入的 ylh-web-front-base 包在主应用存在的情况下自己又初始化一次
    if (window?.JshWomPcSDK?.mounted) {
      return
    }

    window.JshWomPcSDK.init({
      getToken: () => {
        // 避免在没有 token 的时候两个接口同时请求 token，导致后一个 token 把前一个 token 作废而导致 401 的问题
        if (!tokenRequester) {
          tokenRequester = getToken()
        }
        return tokenRequester
      }
    })
  }).fail(() => {
    scriptLoader.preLoad({
      sign: 'JshWomPcSDK',
      scripts: [scriptUrl]
    })
  })
}
