import store from '../../package/store'

export default {
  data () {
    return {
      store: null
    }
  },
  created () {
    this.store = process.env.VUE_APP_RUN_MAIN_APP === 'true' ? this.$store : store
  }
}
