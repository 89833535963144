/**
 * 易理货workbenchse微服务工程接口
 */

const PREFIX = `${process.env.VUE_APP_WB_HOST}/wb`

// 接口列表
export const CloudWorkbench = {
  getYlhHomepageByWhiteList: `${PREFIX}/api/page/member-workbench/white-list/get-ylh-homepage-by-white-list`
}
