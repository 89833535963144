/**
 * 易理货base微服务工程接口
 */

const PREFIX = `/ylh-cloud-service-goods${process.env.VUE_APP_SERVER_SUFFIX}`

// 接口列表
export const CloudGoods = {
  // 首页-你可能关心
  homeMayCareData: `${PREFIX}/api/page/sample/manager/get-sample-concerned-by-member-id`
}
