import qs from 'qs'
import jsonp from 'jsonp'

const convertDataToQuery = (data) => {
  let param = ''
  if (Object.prototype.toString.call(data) === '[object Object]') {
    param = ''
    Object.keys(data).forEach(key => {
      param += `${key}=${data[key]}&`
    })
    if (param.length > 0) { param = param.substring(0, param.length - 1) }
  } else {
    throw new Error('参数错误，data接收为一个对象')
  }
  return param
}

export default (axios) => {
  return {
    /**
     * 普通get请求
     * @param url 请求地址
     * @param data Object 地址参数
     * @param config 请求config
     * @returns {*} Promise 请求结果
     */
    get (url = '', data, config) {
      const query = convertDataToQuery(Object.assign(data || {}, { _t: new Date().getTime() }))
      url += `${url.indexOf('?') > -1 ? '&' : '?'}` + query
      return axios.get(url, config)
    },
    /**
     * 普通post请求
     * @param url 请求地址
     * @param data Object 请求body
     * @param config 请求config 默认contentType:application/json;charset=UTF-8
     * @returns {*} Promise 请求结果
     */
    post (url, data, config) {
      return axios.post(url, data, config)
    },
    /**
     * post表单请求
     * @param url 请求地址
     * @param data Object 请求参数 Object
     * @param config 请求config
     * @returns {*} Promise 请求结果
     */
    postFormData (url, data, config) {
      if (config) {
        config.headers['content-type'] =
          'application/x-www-form-urlencoded; charset=UTF-8'
      } else {
        config = {
          headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        }
      }
      return axios.post(url, data ? qs.stringify(data) : null, config)
    },
    /**
     * jsonp请求
     * @param url 请求地址
     * @param data Object 地址参数
     * @param config jsonp配置 {param:'查询字符串参数的名称',timeout:'超时时间', prefix:'前缀', name:''}
     * @returns {Promise} 请求结果
     */
    jsonp (url, data, config) {
      url += (url.indexOf('?') > -1) ? '&' : '?' + convertDataToQuery(data)
      return new Promise((resolve, reject) => {
        jsonp(url, config, (error, data) => {
          if (!error) {
            resolve(data)
          } else {
            reject(error)
          }
        })
      })
    }
  }
}
