/**
 * 易理货ECM微服务工程接口
 */

const PREFIX = `/ylh-cloud-service-notice${process.env.VUE_APP_SERVER_SUFFIX}`

// 接口列表
export const CloudNotice = {
  // 消息未读数量
  unreadInfo: `${PREFIX}/api/page/announcement/common/get-unread-info`,
  // 消息未读数量
  getshowtypeunreadcount: `${PREFIX}/api/page/announcement/system/get-show-type-unread-count`,
  // 公告类别
  getmsgunreadinfo: `${PREFIX}/api/page/announcement/system/get-msg-un-read-info`,
  // 消息中台消息列表
  searchmsgsysteminfo: `${PREFIX}/api/page/announcement/system/search-msg-system-info`

}
