/**
 * 易理货base微服务工程接口
 */

const PREFIX = `/ylh-cloud-service-base${process.env.VUE_APP_SERVER_SUFFIX}`

// 接口列表
export const CloudBase = {
  // 获取子应用配置列表
  getChildAppConfigList: `${PREFIX}/api/page/app/child-app-config/list`,
  // 获取app版本号
  getAppVersion: `${PREFIX}/api/page/app/system-version/get-version`,
  // 获取桌面端版本号
  getAppVersionAndModule: `${PREFIX}/api/page/app/system-version/get-version-and-module`,
  // 根据aid获取token
  getTokenByAid: `${PREFIX}/api/page/auth/jsh/get-token-by-aid`
}
