/**
 * 金蝶工程接口
 */
const PREFIX = `/jsh-jindie-job-executor${process.env.VUE_APP_SERVER_SUFFIX}`

// 接口列表
export const CloudJindie = {
  // 绑定客户与账套
  saveKingdeeAccountGroup: `${PREFIX}/api/page/kingdee/account-bind-member/save-kingdee-account-group`,
  // 根据账套id获取账套信息
  selectKingdeeAccountGroupByAccountId: `${PREFIX}/api/page/kingdee/account-bind-member/select-kingdee-account-group-by-account-id`,
  // 根据码或者名称
  selectMemberBaseBySellerCode: `${PREFIX}/api/page/kingdee/account-bind-member/select-member-base-by-seller-code`,
  // 获取密钥和偏移量
  searchFundCenterSwitchConfigByCodes: `${PREFIX}/api/page/kingdee/account-bind-member/search-fund-center-switch-config-by-codes`
}
