import vueCookie from 'vue-cookie'
import Vue from 'vue'

// 用户token key
const ACCESS_TOKEN_KEY = 'access_token'
// 刷新token key
const REFRESH_TOKEN_KEY = 'refresh_token'

const YLH_ACCESS_TOKEN_KEY = 'ylh_access_token'
const WOM_ACCESS_TOKEN_KEY = 'wom_access_token'
const MSG_CENTER_ACCESS_TOKEN_KEY = 'msg_center_access_token'

export function getToken () {
  return vueCookie.get('token')
}

export function setToken (token) {
  return localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

export function getRefreshToken () {
  return localStorage.getItem(REFRESH_TOKEN_KEY)
}

export function setRefreshToken (token) {
  return localStorage.setItem(REFRESH_TOKEN_KEY, token)
}

export function getYLHToken () {
  return localStorage.getItem(YLH_ACCESS_TOKEN_KEY)
}

// 从 cookie 或者 local storage 中获取易理货 token
export function getYLHTokenAnyway () {
  return Vue.prototype.$cookie.get('token') || getYLHToken()
}

export function setYLHToken (token) {
  return localStorage.setItem(YLH_ACCESS_TOKEN_KEY, token)
}

export function removeYLHToken () {
  localStorage.removeItem(YLH_ACCESS_TOKEN_KEY)
}

export function getWOMToken () {
  return localStorage.getItem(WOM_ACCESS_TOKEN_KEY)
}

export function setWOMToken (token) {
  return localStorage.setItem(WOM_ACCESS_TOKEN_KEY, token)
}

export function getMsgCenterToken () {
  return localStorage.getItem(MSG_CENTER_ACCESS_TOKEN_KEY)
}

export function setMsgCenterToken (token) {
  return localStorage.setItem(MSG_CENTER_ACCESS_TOKEN_KEY, token)
}

export function clearToken () {
  // 清除消息中台的token
  localStorage.removeItem(MSG_CENTER_ACCESS_TOKEN_KEY)
  // 清除易问e答的token
  localStorage.removeItem(WOM_ACCESS_TOKEN_KEY)
  // 清除易理货的token
  removeYLHToken()
}
