import mainRouter from './router'
import Vue from 'vue'

// 提前绑定路由 beforeEach,解决异步加载 beforeEach 添加太晚导致获取不到路由信息问题
function bindBeforeEach (router) {
  if (!router) {
    return
  }
  const preBeforeEach = router.beforeEach((to, from, next) => {
    const data = {
      params: to.params,
      query: to.query
    }

    // Determine a name for the routing transaction and where that name came from
    let transactionName = ''
    if (to.name) {
      transactionName = to.name.toString()
    }

    let routePath = ''
    // 设置path
    if (to.matched && to.matched.length) {
      routePath = to.matched[to.matched.length - 1].path
    }

    if (window.Statistics) {
      window.Statistics.firstRouteInfo = {
        route_path: routePath,
        params: data.params,
        query: data.query,
        transactionName
      }
    }
    // 解绑 beforeEach
    preBeforeEach()
    next()
  })
}

export const initMainSentry = () => {
  window.$sentry_config = {}
  // 初始化
  if (window.Statistics) {
    bindBeforeEach(mainRouter)
    window.Statistics('init', {
      Vue,
      router: mainRouter,
      sentry: {
        dsn: 'https://9ca94eed7e654d52ad8136ed2a13dd5f@ss.haier.net/1920',
        environment: process.env.VUE_APP_SENTRY_ENV,
        release: 'RELEASE_VERSION_FOR_WEBPACK',
        // 扩展配置，用于处理主子应用异常上报隔离
        // 当异常放生的文件路径，以这个路径开始时，则认为是主应用异常，否则认为是子应用异常
        rootStaticPath: '/home',
        // 当异常发生时，非主应用异常，且子应用未配置dsn，则上报到默认异常上报地址
        defaultErrorDsn: 'https://9ca94eed7e654d52ad8136ed2a13dd5f@ss.haier.net/1920'
      }
    })
  }
}
