import Layout from '../layout'
/**
 * 基座内部页面，需要登录才可以访问，非基座功能严禁在基座内，请挪到对应子应用内部
 */
export default [
  {
    path: '/internal',
    component: Layout,
    children: [
      {
        path: 'demo',
        name: 'Demo',
        component: () => import('../views/demo/demo.vue'),
        meta: {
          title: '演示'
        }
      },
      {
        path: 'menu',
        name: 'menu',
        component: () =>
          import('@/views/menu/menu.vue'),
        meta: {
          keepAlive: false,
          title: ''
        }
      }
    ]
  }
]
