var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.bem()},[_c('div',[_c('div',{staticStyle:{"height":"calc(100vh - 70px)"},attrs:{"id":"i-scroll-js"}},[_c('div',_vm._l((_vm.menuList),function(menu,index){return _c('div',{key:index,staticClass:"js-hover-menu",class:_vm.bem('menu')},[(menu.name === '统计')?_c('a',{staticClass:"menu-title d-block clearfix",class:[_vm.bem('menu-title'), menu.id === _vm.activeMenuId ? 'active': null],attrs:{"href":menu.pcUrl ? menu.pcUrl : 'javascript:;',"id":"homeStep2"}},[_c('div',{staticClass:"menu-title-icon float-left"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id !== _vm.activeMenuId),expression:"menu.id !== activeMenuId"}],attrs:{"src":menu.iconUrl ? menu.iconUrl : _vm.menuDefaultImg,"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id === _vm.activeMenuId),expression:"menu.id === activeMenuId"}],attrs:{"src":menu.selectedIconUrl
                    ? menu.selectedIconUrl
                    : _vm.menuDefaultImgLine,"alt":""}})]),_c('div',{staticClass:"menu-title-text text-truncate float-left"},[_vm._v(" "+_vm._s(menu.name)+" ")])]):(menu.name === '营销')?_c('a',{staticClass:"menu-title d-block clearfix",class:[_vm.bem('menu-title'), menu.id === _vm.activeMenuId ? 'active': null],attrs:{"href":menu.pcUrl ? menu.pcUrl : 'javascript:;',"id":"homeStep3"}},[_c('div',{staticClass:"menu-title-icon float-left"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id !== _vm.activeMenuId),expression:"menu.id !== activeMenuId"}],attrs:{"src":menu.iconUrl ? menu.iconUrl : _vm.menuDefaultImg,"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id === _vm.activeMenuId),expression:"menu.id === activeMenuId"}],attrs:{"src":menu.selectedIconUrl
                    ? menu.selectedIconUrl
                    : _vm.menuDefaultImgLine,"alt":""}})]),_c('div',{staticClass:"menu-title-text text-truncate float-left"},[_vm._v(" "+_vm._s(menu.name)+" ")])]):_c('a',{staticClass:"menu-title d-block clearfix",class:[_vm.bem('menu-title'), menu.id === _vm.activeMenuId ? 'active': null],attrs:{"href":menu.pcUrl ? menu.pcUrl : 'javascript:;'}},[_c('div',{staticClass:"menu-title-icon float-left"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id !== _vm.activeMenuId),expression:"menu.id !== activeMenuId"}],attrs:{"src":menu.iconUrl ? menu.iconUrl : _vm.menuDefaultImg,"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(menu.id === _vm.activeMenuId),expression:"menu.id === activeMenuId"}],attrs:{"src":menu.selectedIconUrl
                    ? menu.selectedIconUrl
                    : _vm.menuDefaultImgLine,"alt":""}})]),_c('div',{staticClass:"menu-title-text text-truncate float-left"},[_vm._v(" "+_vm._s(menu.name)+" ")])]),_c('ul',{ref:`menu-wrap_${menu.id}`,refInFor:true,staticClass:"sub-menu-warp clearfix"},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll"}],staticClass:"menu-wrap-box",on:{"mouseover":_vm.handleMouseOver}},_vm._l((menu.children),function(subMenu,subIndex){return _c('li',{key:subIndex,staticClass:"float-left sub-menu"},[_c('a',{staticClass:"sub-menu-title",attrs:{"href":subMenu.pcUrl ? subMenu.pcUrl : 'javascript:;'}},[_vm._v(" "+_vm._s(subMenu.name)+" ")]),_vm._l((subMenu.children),function(thirdMenu,thirdIndex){return _c('div',{key:thirdIndex,staticClass:"clearfix third-menu-warp",on:{"click":function($event){return _vm.toPage(menu, subMenu, thirdMenu)}}},[_c('div',{staticClass:"float-left third-menu-title",attrs:{"title":thirdMenu.name}},[_c('span',[_vm._v(_vm._s(thirdMenu.name))]),(thirdMenu.promotionUrl)?_c('div',{staticClass:"d-inline-flex align-items-center ml-2"},[_c('img',{staticStyle:{"width":"34px"},attrs:{"src":thirdMenu.promotionUrl,"alt":""}})]):_vm._e()])])})],2)}),0)])])}),0)]),_c('div',{ref:"sidebarToTop",staticClass:"sidebar-to-top",staticStyle:{"display":"none"}}),_c('div',{ref:"sidebarToBottom",staticClass:"sidebar-to-bottom",class:_vm.hasHorizontalScroll ? 'workbench-to-bottom' : '',staticStyle:{"display":"none"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }