<template>
  <div :class="bem()">
    <div class="clearfix bg-fff pr-20">
      <div class="float-left">
        <div class="d-flex align-items-center pl-20" style="height: 69px;">
          <em style="width:4px;height:22px;background-color: #0bb0f6 !important;"/>
          <span
            class="fs-22 color-555 ml-10 mr-10 text-truncate-2"
            style="line-height:28px;"
            :style="{ maxWidth:height }"
          >
            {{ memberName }}
          </span>
        </div>
      </div>
      <div class="float-right">
        <div class="clearfix">
          <div class="float-left mr-20" style="margin-top: 18px;">
            <el-select
              v-model="menuValue"
              remote
              filterable
              clearable
              placeholder="菜单搜索"
              :remote-method="menuSearch"
              @clear="clearFn"
              @change="openMenuUrl"
              value-key="pcUrl"
              no-data-text="暂无符合要求菜单"
              size="small"
              style="width: 180px"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"/>
              <el-option
                v-for="(item) in menuNewList"
                :key="item.pcUrl"
                :value="item"
                :label="item.name"
              >
                <div v-html="showFilterValue(item.name)"/>
              </el-option>
            </el-select>
          </div>

          <!--人工客服-->
          <!-- <div class="float-left  mr-10" id="udesk-customer-btn" :class="bem('phone')">
            <img
              src="../../assets/images/customer-service-icon.png"
              width="22"
              alt=""
            >
            <span style="color: #777777;font-size: 12px;margin-top:3px">
              人工客服
            </span>
          </div> -->
          <!--问题提报-->
          <div
            class="float-left mr-10"
            v-if="isShowQuestion"
            id="questionReport"
            @click="questionReport"
          >
            <div
              style="height: 69px;display: flex; flex-direction: column;justify-content: center;align-items: center;cursor: pointer"
            >
              <img
                src="../../assets/images/e-q-e-a-icon.png"
                width="22"
              >
              <span
                id="questionReportText"
                style="color: #777777;font-size: 12px;margin-top:3px"
              >
                易问e答
              </span>
            </div>
          </div>
          <!--帮助中心-->
          <div
            class="float-left  mr-10"
            :class="bem('help')"
            v-if="helpCenterLink"
            @click="openHelpCenter"
          >
            <img
              src="../../assets/images/help-center-icon.png"
              width="22"
              alt=""
            >
            <span
              style="color: #777777;font-size: 12px;margin-top:3px"
            >
              帮助
            </span>
          </div>
          <!--消息-->
          <div
            class="float-left"
            style="margin-right: 20px"
            :class="bem('message')"
            @click="toPageForHeader()"
          >
            <img
              src="../../assets/images/message-icon.png"
              width="22"
              alt=""
            >
            <span :class="bem('avatar')" v-if="unreadNumber > 0">
              {{
                unreadNumber
              }}
            </span>
            <span
              style="color: #777777;font-size: 12px;margin-top:3px"
            >
              通知
            </span>
          </div>
          <!--用户-->
          <div class="float-left ml-10" :class="bem('user')">
            <div class="text-center">
              <img
                v-if="!userHeadUrl"
                class="mr-10"
                :class="bem('user-avatar')"
                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              >
              <img
                v-if="userHeadUrl"
                class="mr-10"
                :class="bem('user-avatar')"
                :src="userHeadUrl"
              >
            </div>
            <div style="align-items: center;display: flex">
              <div class="fs-12 text-truncate" style="color: #409EFF !important;">
                {{ userName }}
              </div>
              <img
                :class="bem('fold')"
                src="../../assets/images/arrow-down.png"
                width="14"
              >
            </div>
            <!--下拉菜单-->
            <div class="user-handle">
              <div
                class="user-handle-item"
                @click="openCustomerCenterPage('/personalSetting/index')"
              >
                安全中心
              </div>
              <div
                class="user-handle-item"
                @click="openCustomerCenterPage('/personalSetting/index')"
              >
                修改密码
              </div>
              <div class="user-handle-item" @click="logout">
                退出
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <remote-js :src="udeskScriptUrl"/> -->
  </div>
</template>

<script>
import { CloudUser, V2Portal, CloudNotice } from '../../apis'
import { getFullAddress } from '../../utils'
import storeMixin from '../../mixins/store'
import bemMixin from '../../mixins/class-name'
import Vue from 'vue'
import { tellRedirectUrl } from '../../router/utils'
import bus from '../../bus'
import { storage, jwtToken, DocWatermark } from '@jsh/helper/utils'

export default {
  mixins: [storeMixin, bemMixin('layout-header')],
  data () {
    return {
      helpCenterLink: '', // 帮助中心链接
      unreadNumber: 0, // 未读数量
      userName: '', // 用户名称
      memberName: '', // memberName名称
      userHeadUrl: '', // 员工头像
      udeskScriptUrl: `${location.origin}/cloud/assets/jsh/business/udesk/udesk.customer.js`,
      memberId: '',
      isShowQuestion: false,
      menuValue: '',
      menuList: [], // 菜单列表
      menuNewList: [], // 菜单列表
      height: 0,
      wm: null
    }
  },
  methods: {
    // 打开帮助中心
    openHelpCenter () {
      // window.open(this.helpCenterLink)
      window.open(`${window.location.origin}/help/help-home`)
    },

    getMemberBasicInfoByIdOrSellercode () {
      this.$http.post(CloudUser.getMemberBasicInfoByIdOrSellercode, {
        id: this.memberId
      }).then(res => {
        const { success, data } = res.data
        if (success) {
          if (data) {
            const memberFlag = data.memberFlag
            // P-理货商；R-零售商；S-伞下店 展示入口
            if (['P', 'R', 'S', 'O'].includes(memberFlag)) {
              this.isShowQuestion = true
            }
            // 缓存小微信息
            localStorage.setItem(
              'userGmInfo',
              JSON.stringify({
                gmName: data.gmName,
                gmCode: data.gmCode
              } || {})
            )
          }
        }
      })
    },
    // 问题提报
    questionReport () {
      // path: '/webv2//base/question-list'
      location.href = '/pc-wom/question-list'
    },
    // 打开新页面
    openPage (url) {
      location.href = getFullAddress(url)
    },
    toPageForHeader () {
      // window.open('/webv2/portal/messageList')
      window.open('/msg-center/message-manage/message-list')
    },
    // 打开客户中心页面
    openCustomerCenterPage (url) {
      window.open(process.env.VUE_APP_CUSTOMER_CENTER_HOST + url)
    },
    // 退出
    async logout () {
      const res = await this.$http.get(V2Portal.logout)
      if (res.data.success) {
        if (process.env.NODE_ENV === 'development') {
          this.$router.push('/login')
        } else {
          window.location.href = `${process.env.VUE_APP_CUSTOMER_CENTER_HOST}/logout?redirect_uri=${location.origin}`
        }
      }
    },
    // GrowingIO脚本
    GrowingIO (
      growingIOUserId = '0000000000',
      growingIOYlhMemberId,
      growingIOYlhUserName,
      growingIOYlhUserPhone,
      growingIOYlhUserNickName,
      growingIOYlhMemberName
    ) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.innerHTML =
          '!function(e,n,s,t,a){var c;e[t]=e[t]||function(){(e[t].q=e[t].q||[]).push(arguments)};var i="gdp";e[i]=e[i]||function(){(e[t].q=e[t].q||[]).push(arguments)},a=a||"vds",e._gio_local_vds=a,e[a]=null!==(c=e[a])&&void 0!==c?c:{},e[a].namespace=t;var o=n.createElement("script"),r=n.getElementsByTagName("script")[0];o.async=!0,o.src="https://unpkg.haier.net/gio-webjs-sdk@4/dist/gdp-full.js",r.parentNode.insertBefore(o,r)}(window,document,0,"gio");' +
          '    gio("init", "92e3bf168f23aef6", "8aa86f72671511ee", {' +
          '      "version":"4.0",' +
          '      "setImp":"false",' +
          '      "setTrackerScheme":"https",' +
          '      "enableHT": true' +
          '    });' +
          '    gio("setUserId", "' +
          growingIOUserId +
          '");' +
          '    gio("people.set", {' +
          '      "ylh_member_id": "' +
          growingIOYlhMemberId +
          '",' +
          '      "ylh_user_name": "' +
          growingIOYlhUserName +
          '",' +
          '      "ylh_user_phone": "' +
          growingIOYlhUserPhone +
          '",' +
          '      "ylh_user_nickname": "' +
          growingIOYlhUserNickName +
          '",' +
          '      "ylh_member_name": "' +
          growingIOYlhMemberName +
          '"' +
          '    });'
      document.head.appendChild(script)
    },
    // 获取子应用路由地址状态
    getPathStatus (url) {
      // 子应用路由匹配列表
      const microAppPathList = Vue.prototype.MicroApps.map(config => config.router)

      for (let i = 0; i < microAppPathList.length; i++) {
        const regx = new RegExp(`^${microAppPathList[i]}`, 'i')
        if (regx.test(url)) {
          return microAppPathList[i]
        }
      }
      return ''
    },
    // 判断是否含有二期项目路由前缀
    hasAngularJsPath (childRouter) {
      let isAngularjs = false
      let angularPrefix = '';
      ['/cloud/', '/ylh/', '/portal/'].forEach((prefix) => {
        if (location.pathname.indexOf(prefix) > -1) {
          isAngularjs = true
          angularPrefix = prefix
        }
      })
      // 当前为二期项目
      if (isAngularjs) {
        // 非当前项目
        return angularPrefix !== (childRouter + '/')
      }
      // 非二期项目但是要跳转二期项目
      return ['/cloud', '/ylh', '/portal'].includes(childRouter)
    },
    // 远程菜单搜索
    menuSearch (value) {
      const that = this
      that.menuValue = value
      if (that.menuValue) {
        that.menuNewList = []
        that.menuList.forEach(menu => {
          menu.children.forEach(subMenu => {
            subMenu.children.forEach(thirdMenu => {
              if (thirdMenu.name.includes(that.menuValue.trim())) {
                const menuUrl = {
                  ifNewPage: thirdMenu.ifNewPage,
                  pcUrl: thirdMenu.pcUrl ? thirdMenu.pcUrl : `/internal/menu?id=${thirdMenu.id}`,
                  menuId: thirdMenu.id,
                  name: thirdMenu.name,
                  id: thirdMenu.id
                }
                that.menuNewList.push(menuUrl)
              }
              if (thirdMenu.children && thirdMenu.children.length !== 0) {
                that.recursive(thirdMenu.children, value)
              }
            })
          })
        })
      } else {
        this.menuNewList = []
      }
    },
    // 递归处理列表
    recursive (list, value) {
      const that = this
      list.forEach((item, index) => {
        // 存在pcUrl加入搜索列表
        if (item.name.includes(value)) {
          const menuUrl = {
            ifNewPage: item.ifNewPage,
            pcUrl: item.pcUrl ? item.pcUrl : `/internal/menu?id=${item.id}`,
            menuId: item.id,
            name: item.name,
            id: item.id
          }
          that.menuNewList.push(menuUrl)
        }
        if (item.children && item.children.length !== 0) {
          that.recursive(item.children, value)
        }
      })
    },
    clearFn () {
      this.menuNewList = []
      this.menuValue = ''
    },
    // 搜索菜单跳转
    openMenuUrl (thirdMenu) {
      if (thirdMenu) {
        const childRouter = this.getPathStatus(thirdMenu.pcUrl)
        if (childRouter) {
          // 子应用工程
          if (thirdMenu.pcUrl) {
            const url = tellRedirectUrl(thirdMenu.pcUrl)
            window.open(url)
            this.store.commit('changeActiveMenuId', thirdMenu.id)
          }
        } else {
          window.open(thirdMenu.pcUrl)
        }
      }
    },
    showFilterValue (item) {
      return item.replace(
        this.menuValue,
        '<font color="#409EFF">' + this.menuValue + '</font>'
      )
    },
    // 初始化水印
    initWatermark () {
      const tokenData = jwtToken.decode(this.$cookie.get('token'))
      this.wm = new DocWatermark({
        content: `${tokenData.seller_code ? (tokenData.seller_code === tokenData.user_name ? tokenData.seller_code : `${tokenData.seller_code}-${tokenData.user_name}`) : tokenData.user_name}`,
        fullFixed: true,
        height: 150,
        width: 150,
        zIndex: 9999,
        angle: 15
      })
    }
  },
  created () {
    const userInfo = localStorage.getItem('userInfo')
    const sessionId = localStorage.getItem('sessionId')
    const tokenIdent = localStorage.getItem('tokenIdent')
    this.height = (document.body.clientWidth - 120) * 0.42 + 'px'
    console.log('userInfo', userInfo)
    if (userInfo) {
      try {
        this.memberId = JSON.parse(userInfo).member.id
      } catch (e) {
        console.log('userInfo', userInfo)
      }
      this.$http.get(CloudUser.getUserInfoById, {
        userId: JSON.parse(userInfo).id
      }).then(res => {
        if (res.data.success) {
          localStorage.setItem('accountId', res.data.data.accountId)
        }
      })
      this.initWatermark()
    }
    // 如果用户信息不存在或者用户信息不一致，则获取一次存入本地
    if (!userInfo || tokenIdent !== this.$cookie.get('token')?.substr(-16)) {
      // 初始化获取me接口
      this.$http.get(V2Portal.me).then(res => {
        if (res.data.success && res.data.data.ylh_user_info) {
          // 存入本地存储
          localStorage.setItem(
            'userInfo',
            JSON.stringify(res.data.data.ylh_user_info || {})
          )
          this.initWatermark()
          try {
            storage.setItem('tokenData', jwtToken.decode(this.$cookie.get('token')))
          } catch (e) {}
          // bus.$emit('userInfo', JSON.stringify(res.data.data.ylh_user_info))
          localStorage.setItem('sessionId', res.data.data.sessionid)
          localStorage.setItem(
            'shopInfo',
            JSON.stringify(res.data.data.ylh_shop_info)
          )
          localStorage.setItem(
            'tokenIdent',
            this.$cookie.get('token').substr(-16)
          )

          this.helpCenterLink = `http://help.yilihuo.com?JSESSIONID=${res.data
              .data.sessionid || ''}&type=1`
          this.userName = res.data.data.ylh_user_info.userName
          this.memberName = res.data.data.ylh_user_info.member.memberName
          const infostr = localStorage.getItem('userInfo')
          const info = JSON.parse(infostr)
          this.$http.get(CloudUser.getUserInfoById, {
            userId: info.id
          }).then(res => {
            if (res.data.success) {
              localStorage.setItem('accountId', res.data.data.accountId)
            }
          })
          this.GrowingIO(
            info.id,
            info.member.id,
            info.userName,
            info.userPhone,
            info.userNick,
            info.member.memberName
          )
        }
      })
    } else {
      this.helpCenterLink = `http://help.yilihuo.com?JSESSIONID=${sessionId ||
        ''}&type=1`
      this.userName = JSON.parse(userInfo).userName
      this.memberName = JSON.parse(userInfo).member.memberName
      const infostr = localStorage.getItem('userInfo')
      const info = JSON.parse(infostr)
      this.GrowingIO(
        info.id,
        info.member.id,
        info.userName,
        info.userPhone,
        info.userNick,
        info.member.memberName
      )
    }

    // 初始化获取未读数量
    this.$http.post(CloudNotice.getshowtypeunreadcount).then(res => {
      if (res.data.success) {
        this.unreadNumber = res.data.data.unReadCount
      }
    })

    // 初始化获取员工头像
    this.$http.get(CloudUser.userInfoIndex).then(res => {
      if (res.data.success && res.data.data) {
        if (res.data.data.portraitPath) {
          this.userHeadUrl = res.data.data.portraitPath
        } else {
          this.userHeadUrl = ''
        }
      }
    })
    this.getMemberBasicInfoByIdOrSellercode()

    // 初始化获取用户角色和商家类型:用于gio上报
    this.$http.get(CloudUser.getMemberFlagAndRole).then(res => {
      if (res.data.success) {
        localStorage.setItem('memberFlagAndRole', JSON.stringify({
          memberFlagName: res.data.data.memberFlagName,
          userRoleList: res.data.data.userRoleList?.map(_ => _.roleName).join(',')
        }))
      }
    })
  },
  mounted () {
    const that = this
    bus.$on('init-menu-list', () => {
      that.menuList = JSON.parse(localStorage.getItem('menuList')) || []
    })
  },
  beforeDestroy () {
    // 销毁水印 一定要销毁
    if (this.wm) { this.wm.destroy() }
  }
}
</script>

<style lang="scss" scoped>
  .layout-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    padding-left: 120px;
    background-color: #fff;
    border-bottom: 1px #f2f2f2 solid;
    z-index: 18;

    &::v-deep .el-input__inner {
      border-radius: 16px;
    }

    &_phone,
    &_help,
    &_message {
      height: 69px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_user {
      height: 69px;
      padding-top: 5px;
      /*display: flex;*/
      justify-content: center;
      align-items: center;
    }

    &_phone {
      width: 60px;
      display: flex !important;
      flex-direction: column;
      cursor: pointer;
    }

    &_help {
      width: 50px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &_message {
      width: 40px;
      flex-direction: column;
      cursor: pointer;
      position: relative;
    }

    &_avatar {
      position: absolute;
      top: 8px;
      left: 20px;
      height: 16px;
      min-width: 16px;
      color: #fff;
      background-color: #ff7b7b;
      font-size: 12px;
      border-radius: 8px;
      text-align: center;
      line-height: 16px;
      display: block;
      padding: 0 4px;
      box-sizing: border-box;
    }

    &_user {
      max-width: 160px;
      position: relative;

      .user-handle {
        display: none;
        position: absolute;
        top: 65px;
        right: 0;
        z-index: 15;

        .user-handle-item {
          background-color: #2f343c;
          color: #fff;
          width: 100px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
        }
      }

      &:hover {
        .user-handle {
          display: block;

          .user-handle-item:hover {
            background-color: #0BB0F6 !important;
          }
        }
      }
    }

    &_user-avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }

    &_fold {
      margin-left: 6px;
    }
  }
</style>
