import { trim } from '../utils'

const redirectPages = {
  '/webv2/shipment-processing-page': {
    word: '/webv2/stock/shipment-processing',
    pic: '/stock/shipment-processing'
    // pic: ({ orderCode = '' }) => `/cloud/#!/accounting/shipmentProcessing/${orderCode}`
  },
  '/webv2/shipment-history-page': {
    word: '/webv2/stock/shipment-history',
    pic: '/stock/shipment-history'
  },
  '/webv2/outstore-processing-page': {
    word: '/webv2/stock/outstore-processing',
    // pic: ({ orderCode = '' }) => `/cloud/#!/accounting/outStoreProcessing/${orderCode}`
    pic: '/stock/outstore-processing'
  },
  '/webv2/outstore-history-page': {
    word: '/webv2/stock/outstore-history',
    // pic: ({ orderCode = '' }) => `/cloud/#!/accounting/outStoreHistory/${orderCode}`
    pic: '/stock/outstore-history'
  },
  '/webv2/receiving-manage-page': {
    word: '/webv2/stock/receiving-manage',
    pic: '/stock/receiving-manage-image-text'
    // pic: ({ orderCode = '' }) => `/cloud/#!/accounting/theGoodsHandle/${orderCode}
  },
  '/webv2/receiving-history-page': {
    word: '/webv2/stock/receiving-history',
    // pic: ({ orderCode = '' }) => `/cloud/#!/accounting/theGoodsHistory/${orderCode}`
    pic: '/stock/receiving-history'
  },
  '/webv2/intoStore-processing-page': {
    word: '/webv2/stock/intoStore-processing',
    // pic: ({ orderCode = '' }) => `/cloud/#!/accounting/warehousingHandle/${orderCode}`
    pic: '/stock/instore-processing'
  },
  '/webv2/intoStore-history-page': {
    word: '/webv2/stock/intoStore-history',
    // pic: ({ id = '' }) => `/cloud/#!/accounting/warehousingHistory/${id}`,
    pic: '/stock/instore-history'
  },
  '/webv2/transfer-store-history-page': {
    word: '/webv2/stock/transfer-store-history',
    pic: '/stock/transferstore-history'
  },
  '/webv2/stock-history-page': {
    word: '/webv2/stock/stock-history',
    pic: '/stock/other-receive-goods-history'
  },
  '/webv2/out-goods-history-page': {
    word: '/webv2/stock/out-goods-history',
    pic: '/stock/out-goods-history'
  }
}

// 将 url params 格式化为 object
export function search2Object (search) {
  if (search) {
    return Object.fromEntries(search.slice(1).split('&').map(_ => _.split('=')))
  }
  return {}
}

// 判断是否存在路由转发规则
export function tellRedirectUrl (url) {
  // 为了避免因为大小写导致的转发失败，所以统一小写匹配
  const trueUrl = url.toLowerCase()
  const target = Object.keys(redirectPages).find(_ => trueUrl.startsWith(_.toLowerCase()))
  if (target) {
    const display = localStorage.getItem('display-shipmentprocessing')
    const type = !display || display === 'wordType' ? 'word' : 'pic'
    const search = url.includes('?') ? url.slice(url.indexOf('?')) : ''

    let result = redirectPages[target][type]
    if (typeof result === 'function') {
      result = result(search2Object(search))
    } else {
      result += search
    }

    return result
  }
  return url
}

/**
 * 解决重定向的菜单不高亮问题：将重定向路由放到缓存menuList中
 */
export function extendMenuList (menuList) {
  menuList.forEach(menu => {
    if (menu.children?.length) {
      extendMenuList(menu.children)
    } else {
      if (menu.pcUrl && menu.pcUrl in redirectPages) {
        const target = redirectPages[menu.pcUrl]
        menu.word = target.word
        menu.pic = target.pic
      }
    }
  })
  return menuList
}
/** 判断href中是否包含指定路由 */
export function checkUrlListInHref (urlList) {
  return urlList.some(url => (
    url &&
    decodeURI(window.location.href).indexOf(trim(url)) > -1
  ))
}

/** 判断指定路由是否包含当前路由 */
export function checkUrlCurrentInTarget (targetUrl, currentUrl) {
  return targetUrl && trim(targetUrl).indexOf(currentUrl) > -1
}
