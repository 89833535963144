import { storage } from '@jsh/helper/utils'
import { tellIsIframe } from '../utils'

export default {
  changeNetworkOnline (state, payload) {
    state.network.online = payload
  },
  changeBeforeOfflinePath (state, payload) {
    state.network.beforeOfflinePath = payload
    storage.setItem('beforeOfflinePath', payload)
  },
  changeUserInfo (state, payload) {
    state.userInfo = payload
    storage.setItem('userInfo', payload)
  },
  changeCommonMenu (state, Payload) {
    state.commonMenuList = Payload
  },
  changeDisplayAppManageModal (state, Payload) {
    state.isDisplayAppManageModal = Payload
  },
  changeBreadcrumbList (state, Payload) {
    state.breadcrumbList = Payload
  },
  changePermissionList (state, Payload) {
    state.permissionList = Payload || []
  },
  changePricePermissionList (state, Payload) {
    state.pricePermissionList = Payload || []
  },
  changeActiveMenuId (state, Payload) {
    state.activeMenuId = Payload
  },
  tellIsIframe (state) {
    let isIframe = tellIsIframe()

    const { classList } = document.body
    const className = 'body-in-iframe'
    const hasClassName = classList.contains(className)
    if (isIframe) {
      isIframe = window.localStorage.getItem('iframe_source') || isIframe

      if (!hasClassName) {
        classList.add(className)
      }
    } else {
      if (hasClassName) {
        classList.remove(className)
      }
    }

    state.isIframe = isIframe
  }
}
