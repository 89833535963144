/**
 * 易理货base微服务工程接口
 */

const PREFIX = `/ylh-cloud-service-task${process.env.VUE_APP_SERVER_SUFFIX}`

// 接口列表
export const CloudTask = {
  // 任务列表
  listTask: `${PREFIX}/api/page/task/customer/management/list-task`,
  // 任务管理-我的任务待分配/待办的数量
  getTaskNum: `${PREFIX}/api/page/task/customer/management/get_task_num`
}
