import { jwtToken } from '@jsh/helper/utils'
import { getYLHToken } from './auth'
import { tellIsIframe } from './index'

const open = window.open
window.open = (url, ...args) => {
  const host = url.startsWith('http')
    ? url.match(/^http(s|):\/\/\S*?\//)?.[0] || ''
    : window.location.hostname
  const toYLH = ['ylhtest.com', 'yilihuo.com'].some(_ => host.includes(_))

  if (toYLH && tellIsIframe()) {
    // 仅在从 iframe ylh 跳转到 tab ylh 时，携带 loginUserId，用于判断两个环境下登录的用户是否一致
    const token = jwtToken.decode(getYLHToken())
    const hasQuery = url.includes('?')
    return open(`${url}${hasQuery ? '&' : '?'}_YIB_loginUserId=${token?.user_id}`, ...args)
  } else {
    return open(url, ...args)
  }
}
