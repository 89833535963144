import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout'
import Offline from '../views/error/offline.vue'
import NoAccess from '../views/error/403.vue'
import NoPage from '../views/error/404.vue'
import ExternalPages from './external-pages'
import InternalPages from './internal-pages'
import { tellRedirectUrl } from './utils'
import store from '../store'
import { resetReport, setUser } from '@jsh/report-page-time'
import { storage } from '@jsh/helper/utils'
import bus from '../bus'
import { CloudUser } from '../apis'
import { getMsgCenterToken, getWOMToken, setMsgCenterToken, setWOMToken } from '../utils/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/home.vue'),
        meta: {
          keepAlive: false,
          title: '首页'
        }
      },
      {
        path: 'workbench',
        name: 'Workbench',
        component: () => import('@/views/workbench/workbench.vue'),
        meta: {
          keepAlive: false,
          title: '客户工作台'
        }
      }
    ]
  },
  ...ExternalPages,
  ...InternalPages,
  {
    path: '/offline',
    component: Offline,
    meta: {
      keepAlive: true,
      title: '网络异常'
    }
  },
  {
    path: '/403',
    component: NoAccess,
    meta: {
      keepAlive: true,
      title: '访问拒绝'
    }
  },
  {
    path: '/404',
    component: NoPage,
    meta: {
      keepAlive: true,
      title: '找不到页面'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

// 路由白名单
// const WHITE_LIST = ['/404', '/403', '/offline', '/login']

router.beforeEach(async (to, from, next) => {
  // 从缓存中取出权限列表
  const permissionListStr = localStorage.getItem('permissionList')
  const permissionList = JSON.parse(permissionListStr)

  if (
    permissionList &&
    to.meta.permission &&
    !permissionList.includes(to.meta.permission)
  ) {
    // 没有权限则跳转404页面
    next({ path: '/404' })
  }

  const { fullPath } = to
  const turePath = tellRedirectUrl(fullPath)
  if (fullPath !== turePath) {
    window.location.href = turePath
    return
  }

  // 页面标签标题
  if (to.meta.title) {
    document.title = to.meta.title
  }

  const tokenData = storage.getItem('tokenData') || {}
  const userInfo = storage.getItem('userInfo') || {}
  setUser({
    system: 'ylh',
    accountId: userInfo.id,
    accountName: userInfo.userName,
    sellerCode: tokenData.seller_code
  })

  // 消息中台令牌集成
  if (/https?:\/\/.*?\/msg-center\//.test(location.href)) {
    // 判断消息中台令牌是否存在
    if (!getMsgCenterToken()) {
      try {
        // 重新获取令牌
        const result = await Vue.prototype.$http.post(CloudUser.getMsgCenterToken, {
          merchantId: '6',
          source: 'ecm'
        })
        if (result.data.success) {
          setMsgCenterToken(result.data.data.access_token)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  // 易问e答令牌集成
  if (/https?:\/\/.*?\/pc-wom\//.test(location.href)) {
    // 判断易问e答令牌是否存在
    if (!getWOMToken()) {
      try {
        // 重新获取令牌
        const result = await Vue.prototype.$http.get(CloudUser.getMgWomToken)
        if (result.data && result.data.success && result.data.data && result.data.data.access_token) {
          setWOMToken(result.data.data.access_token)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  // 易问E答浮窗组件初始化
  // setTimeout(() => {
  //   window.JshWomPcSDK.init({
  //     getToken: async () => {
  //       const result = await Vue.prototype.$http.get(CloudUser.getMgWomToken)
  //       if (result.data && result.data.success && result.data.data && result.data.data.access_token) {
  //         return result.data.data.access_token
  //       }
  //       return null
  //     }
  //   }, 50)
  // })
  // 判断是否在 iframe 中
  store.commit('tellIsIframe')

  next()
})

router.afterEach((to, from) => {
  // 带 iframe 的页面会导致事件触发两次
})

// 子应用路由跳转后触发
bus.$on('child_router_afterEach', (to, from) => {
  if (process.env.NODE_ENV === 'production') {
    resetReport({
      referrer: `${location.origin}${from.fullPath}`
    })
  }
})

// 添加子应用路由
const addChildRouter = (MicroApps) => {
  const routerList = MicroApps.map(config => config.router).map(child => {
    return {
      path: child + '*',
      component: Layout,
      children: [
        {
          path: '',
          component: () => import('../views/micro-app/micro-app.vue')
        }
      ]
    }
  })
  router.addRoutes([...routerList, {
    path: '*',
    component: NoPage,
    meta: {
      keepAlive: true,
      title: '找不到页面'
    }
  }])
}

export {
  addChildRouter
}

export default router
