/**
 * 易理货ECM微服务工程接口
 */

const PREFIX = `/ylh-cloud-service-user${process.env.VUE_APP_SERVER_SUFFIX}`

// 接口列表
export const CloudUser = {
  // 获取客户信息
  getUserInfoById: `${PREFIX}/api/page/user/info/get-user-by-id`,
  // 获取框架员工信息
  userInfoIndex: `${PREFIX}/api/page/user/info/search-user-info-index`,
  // 菜单统计
  updateTotalCount: `${PREFIX}/api/page/sys-permission/common-menu/update-total-count`,
  // 常用功能菜单列表
  commonMenu: `${PREFIX}/api/page/sys-permission/common-menu/search-common-menu-list`,
  // 新增常用功能菜单
  addCommonMenu: `${PREFIX}/api/page/sys-permission/common-menu/insert-common-menu`,
  // 删除常用功能菜单
  deleteCommonMenu: `${PREFIX}/api/page/sys-permission/common-menu/delete-common-menu`,
  // 引导页
  getGuidePage: `${PREFIX}/api/page/guide/guide-setting/select-guide-setting-by-condition`,
  // 上报
  ylhGuideLog: `${PREFIX}/api/page/guide/guide-setting/save-ylh-guide-log-by-user-id`,
  // 获取价格权限列表
  pricePermissionList: `${PREFIX}/api/page/user/permission/list-user-price-permission`,
  getMemberBasicInfoByIdOrSellercode: `${PREFIX}/api/page/memberCompany/memberBasic/get-member-basic-info-by-id-or-sellercode`,
  // 广告详情
  advertInfo: `${PREFIX}/api/page/advertisement/ylh-ad-management/get-ylh-ad-info-by-id`,
  // 获取菜单对应的广告
  searchSysMenuPortalConfigByMenuId: `${PREFIX}/api/page/access/menu-management/search-sys-menu-portal-config-by-menu-id`,
  // 获取消息中台的令牌信息
  getMsgCenterToken: `${PREFIX}/api/page/auth-center/get-msg-access-token-by-user-id`,
  // 根据用户信息获取客户类型和用户所属角色信息
  getMemberFlagAndRole: `${PREFIX}/api/page/user/info/get-member-flag-and-role-by-user-id`,
  // 修改员工姓名
  updateUserStaffInfo: `${PREFIX}/api/page/user/info/update-user-staff-info`,
  // 获取问卷星的跳转地址
  getCompleteJumpUrl: `${PREFIX}/api/page/advertisement/ylh-ad-management/get-complete-jump-url`,
  // 统计广告展示次数
  setAdDeviceLog: `${PREFIX}/api/page/advertisement/ylh-ad-management/insert-ylh-ad-device-log`,
  // 首页右侧广告
  getAdvertInfoList: `${PREFIX}/api/page/advertisement/ylh-ad-management/list-ad-info-by-type`,
  // 广告统计
  insertYlhAdcountLog: `${PREFIX}/api/page/advertisement/ylh-ad-management/insert-ylh-ad-count-log`,
  // 获取名字是否合规定
  getUserStaffInfo: `${PREFIX}/api/page/user/info/get-user-staff-info`,
  // 年报-获取aid
  getAid: `${PREFIX}/api/page/member/report/annual-report/get-periphery-token-by-seller-code`,
  // 视频-获取阿里云播放全地址
  getAliVideoUrl: `${PREFIX}/api/page/vod/get-vod-address`,
  // 获取营销学院aid
  getAuthTokenForPc: `${PREFIX}/api/page/meeting/auth/token/get-auth-token-for-pc`,
  // 获取易问e答的令牌信息
  getMgWomToken: `${PREFIX}/api/page/scene/get-mg-wom-token`,
  // 直播大厅进入列表
  listLiveInfoList: `${PREFIX}/api/page/live/list-live-info-list`
}
