<template>
  <div :class="bem()">
    <div>
      <div id="i-scroll-js" style="height: calc(100vh - 70px)">
        <div>
          <!--循环一级菜单-->
          <div
            class="js-hover-menu"
            :class="bem('menu')"
            v-for="(menu, index) of menuList"
            :key="index"
          >
            <a
              class="menu-title d-block clearfix"
              :class="[bem('menu-title'), menu.id === activeMenuId ? 'active': null]"
              :href="menu.pcUrl ? menu.pcUrl : 'javascript:;'"
              v-if="menu.name === '统计'"
              id="homeStep2"
            >
              <div class="menu-title-icon float-left">
                <img
                  :src="menu.iconUrl ? menu.iconUrl : menuDefaultImg"
                  v-show="menu.id !== activeMenuId"
                  alt=""
                >
                <img
                  :src="
                    menu.selectedIconUrl
                      ? menu.selectedIconUrl
                      : menuDefaultImgLine
                  "
                  v-show="menu.id === activeMenuId"
                  alt=""
                >
              </div>
              <!--一级标题-->
              <div class="menu-title-text text-truncate float-left">
                {{ menu.name }}
              </div>
            </a>
            <a
              class="menu-title d-block clearfix"
              :class="[bem('menu-title'), menu.id === activeMenuId ? 'active': null]"
              :href="menu.pcUrl ? menu.pcUrl : 'javascript:;'"
              v-else-if="menu.name === '营销'"
              id="homeStep3"
            >
              <div class="menu-title-icon float-left">
                <img
                  :src="menu.iconUrl ? menu.iconUrl : menuDefaultImg"
                  v-show="menu.id !== activeMenuId"
                  alt=""
                >
                <img
                  :src="
                    menu.selectedIconUrl
                      ? menu.selectedIconUrl
                      : menuDefaultImgLine
                  "
                  v-show="menu.id === activeMenuId"
                  alt=""
                >
              </div>
              <!--一级标题-->
              <div class="menu-title-text text-truncate float-left">
                {{ menu.name }}
              </div>
            </a>
            <a
              class="menu-title d-block clearfix"
              :class="[bem('menu-title'), menu.id === activeMenuId ? 'active': null]"
              :href="menu.pcUrl ? menu.pcUrl : 'javascript:;'"
              v-else
            >
              <div class="menu-title-icon float-left">
                <img
                  :src="menu.iconUrl ? menu.iconUrl : menuDefaultImg"
                  v-show="menu.id !== activeMenuId"
                  alt=""
                >
                <img
                  :src="
                    menu.selectedIconUrl
                      ? menu.selectedIconUrl
                      : menuDefaultImgLine
                  "
                  v-show="menu.id === activeMenuId"
                  alt=""
                >
              </div>
              <!--一级标题-->
              <div class="menu-title-text text-truncate float-left">
                {{ menu.name }}
              </div>
            </a>
            <ul
              class="sub-menu-warp clearfix"
              :ref="`menu-wrap_${menu.id}`"
            >
              <div v-scroll class="menu-wrap-box" @mouseover="handleMouseOver">
                <!--循环二级菜单-->
                <li
                  class="float-left sub-menu"
                  v-for="(subMenu, subIndex) of menu.children"
                  :key="subIndex"
                >
                  <!--二级标题-->
                  <a
                    class="sub-menu-title"
                    :href="subMenu.pcUrl ? subMenu.pcUrl : 'javascript:;'"
                  >
                    {{ subMenu.name }}
                  </a>
                  <!--循环三级菜单-->
                  <div
                    class="clearfix third-menu-warp"
                    v-for="(thirdMenu, thirdIndex) of subMenu.children"
                    :key="thirdIndex"
                    @click="toPage(menu, subMenu, thirdMenu)"
                  >
                    <div
                      class="float-left third-menu-title"
                      :title="thirdMenu.name"
                    >
                      <span>{{ thirdMenu.name }}</span>
                      <div
                        class="d-inline-flex align-items-center ml-2"
                        v-if="thirdMenu.promotionUrl"
                      >
                        <img :src="thirdMenu.promotionUrl" alt="" style="width: 34px;">
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="sidebar-to-top"
        ref="sidebarToTop"
        style="display: none"
      />
      <div
        class="sidebar-to-bottom"
        :class="hasHorizontalScroll ? 'workbench-to-bottom' : ''"
        ref="sidebarToBottom"
        style="display: none"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Default, CloudUser } from '../../apis'
import { trim } from '../../utils'
import { checkUrlListInHref, extendMenuList, tellRedirectUrl } from '../../router/utils'
import MenuDefaultImg from '../../assets/images/menu-default.png'
import MenuDefaultImgLine from '../../assets/images/menu-default-line.png'
import IScroll from 'iscroll/build/iscroll-probe'
import storeMixin from '../../mixins/store'
import bemMixin from '../../mixins/class-name'
import bus from '../../bus'

export default {
  mixins: [storeMixin, bemMixin('layout-sidebar')],
  data () {
    return {
      menuDefaultImg: MenuDefaultImg, // 默认一级菜单图标
      menuDefaultImgLine: MenuDefaultImgLine, // 选中的以及菜单
      menuList: [], // 菜单列表
      iScroll: false,
      hasHorizontalScroll: false // 是否有横向滚动条  因为没有最下面固定的应用中心菜单了  所以滚动时出现的快速点击按钮会被滚动条盖住 定位需要调整
    }
  },
  computed: {
    // 常用功能列表
    commonMenuList () {
      return this.store.state.commonMenuList || []
    },
    // 常用菜单管理弹出框
    manageModal () {
      return this.store.state.isDisplayAppManageModal
    },
    // 激活菜单id
    activeMenuId () {
      return this.store.state.activeMenuId
    }
  },
  methods: {
    checkHorizontalScroll () {
      const scrollableWidth = document.body.scrollWidth
      const viewportWidth = document.documentElement.clientWidth
      this.hasHorizontalScroll = (scrollableWidth - 2) > viewportWidth
    },
    // 去首页
    toHomePage () {
      this.store.commit('changeActiveMenuId', 0)
      if (this.hasAngularJsPath('/webv2')) {
        window.location.href = '/home'
      } else {
        this.$router.push('/', () => {})
      }
    },
    // 去应用管理
    toAppManagePage () {
      this.store.commit('changeActiveMenuId', -1)
      if (this.hasAngularJsPath('/webv2')) {
        window.location.href = '/webv2/base/application-center'
      } else {
        this.$router.push('/webv2/base/application-center', () => {})
      }
    },
    // 获取子应用路由地址状态
    getPathStatus (url) {
      // 子应用路由匹配列表
      const microAppPathList = Vue.prototype.MicroApps.map(config => config.router)

      for (let i = 0; i < microAppPathList.length; i++) {
        const regx = new RegExp(`^${microAppPathList[i]}`, 'i')
        if (regx.test(url)) {
          return microAppPathList[i]
        }
      }
      return ''
    },
    // 判断是否含有二期项目路由前缀
    hasAngularJsPath (childRouter) {
      let isAngularjs = false
      let angularPrefix = '';
      ['/cloud/', '/ylh/', '/portal/'].forEach((prefix) => {
        if (location.pathname.indexOf(prefix) > -1) {
          isAngularjs = true
          angularPrefix = prefix
        }
      })
      // 当前为二期项目
      if (isAngularjs) {
        // 非当前项目
        return angularPrefix !== (childRouter + '/')
      }
      // 非二期项目但是要跳转二期项目
      return ['/cloud', '/ylh', '/portal'].includes(childRouter)
    },
    // 左侧菜单跳转
    toPage (menu, subMenu, thirdMenu) {
      this.$http.post(CloudUser.updateTotalCount, {
        menuId: thirdMenu.id
      }).then(res => {
        if (res.data.success) {
          if (thirdMenu.children && thirdMenu.children.length > 0) {
            //  含有子级菜单，打开新页面
            if (thirdMenu.ifNewPage === 1) {
              window.open(`/internal/menu?id=${thirdMenu.id}`)
            } else {
              this.$router.push(`/internal/menu?id=${thirdMenu.id}`)
            }
            return
          }
          const menuDom = this.$refs[`menu-wrap_${menu.id}`]
          if (menuDom?.[0]) {
            menuDom[0].style.display = 'none'
          }

          const childRouter = this.getPathStatus(thirdMenu.pcUrl)
          if (childRouter) {
            // 子应用工程
            if (thirdMenu.pcUrl) {
              const url = tellRedirectUrl(thirdMenu.pcUrl)

              if (thirdMenu.ifNewPage === 1) {
                window.open(url)
                this.store.commit('changeActiveMenuId', menu.id)
              } else {
                if (this.hasAngularJsPath(childRouter)) {
                  window.location.href = url
                } else {
                  this.$router.push(url).catch(err => console.log(err))
                }

                this.store.commit('changeActiveMenuId', menu.id)
              }
            }
          } else {
            // 其他工程
            if (thirdMenu.ifNewPage === 1) {
              window.open(thirdMenu.pcUrl)
            } else {
              window.location.href = thirdMenu.pcUrl
            }
          }
        } else {
          // ....
        }
      })
    },
    // 常用功能跳转
    toPageForCommon (commonMenu) {
      this.$http.post(CloudUser.updateTotalCount, {
        menuId: commonMenu.menuId
      }).then(res => {
        if (res.data.success) {
          const menuDom = this.$refs['menu-wrap_common']
          if (menuDom) {
            menuDom.style.display = 'none'
          }

          const childRouter = this.getPathStatus(commonMenu.pcUrl)
          if (childRouter) {
            // 属于子应用工程
            if (commonMenu.pcUrl) {
              const url = tellRedirectUrl(commonMenu.pcUrl)

              if (commonMenu.ifNewPage === 1) {
                window.open(url)
              } else {
                if (this.hasAngularJsPath(childRouter)) {
                  window.location.href = url
                } else {
                  this.$router.push(url).catch(err => console.log(err))
                }
              }
            }
          } else {
            // 其他工程
            if (commonMenu.ifNewPage === 1) {
              window.open(commonMenu.pcUrl)
            } else {
              window.location.href = commonMenu.pcUrl
            }
          }
        } else {
          // ....
        }
      })
    },
    // 打开帮助中心
    openHelpCenter () {
      window.open(`${window.location.origin}/help/help-home`)
    },
    // 初始化侧边栏交互
    initSidebarUE () {
      const owner = this
      this.$nextTick(() => {
        this.iScroll = new IScroll('#i-scroll-js', {
          preventDefault: false,
          mouseWheel: true, // 允许鼠标滚轮
          probeType: 2
        })

        function resetToBottom () {
          if (!owner.$refs.sidebarToBottom) { return }
          if (
            owner.iScroll.scrollerHeight + owner.iScroll.y >
            owner.iScroll.wrapperHeight
          ) {
            owner.$refs.sidebarToBottom.style.display =
              'block'
          } else {
            owner.$refs.sidebarToBottom.style.display =
              'none'
          }
        }
        function resetToTop () {
          if (!owner.$refs.sidebarToTop) { return }
          if (owner.iScroll.y < 0) {
            owner.$refs.sidebarToTop.style.display = 'block'
          } else {
            owner.$refs.sidebarToTop.style.display = 'none'
          }
        }
        resetToBottom()
        resetToTop()
        owner.$refs.sidebarToBottom.onclick = () => {
          this.iScroll.scrollTo(0, -(this.iScroll.scrollerHeight - this.iScroll.wrapperHeight), 300)
          setTimeout(function () {
            resetToBottom()
            resetToTop()
          }, 300)
        }
        owner.$refs.sidebarToTop.onclick = () => {
          this.iScroll.scrollTo(0, 0, 300)
          setTimeout(function () {
            resetToBottom()
            resetToTop()
          }, 300)
        }

        const menuNodes = document.querySelectorAll('.js-hover-menu')
        const tellOverflow = element => {
          element.style.top = ''

          const { top, bottom, y, height } = element.getBoundingClientRect()
          if (top < 20) {
            // 76 = $menu-title(56) + 20
            element.style.top = `${76 - y}px`
          } else if (bottom > window.innerHeight - 20) {
            // 36 = $menu-title(56) - 20
            element.style.top = `${window.innerHeight + 36 - height - top}px`
          }
        }
        for (const ele of menuNodes) {
          const child = ele.lastChild
          ele.addEventListener('mouseenter', () => {
            child.style.display = 'block'
            tellOverflow(child)
          })
          ele.addEventListener('mouseleave', () => {
            child.style.display = 'none'
            child.style.top = ''
          })
        }

        this.iScroll.on('scroll', () => {
          const element = document.querySelector('.js-hover-menu:hover > .sub-menu-warp')
          if (element) {
            tellOverflow(element)
          }

          resetToBottom()
          resetToTop()
        })
      })
    },
    // 初始化查询侧边栏菜单
    initSidebarMenu () {
      this.$http.get(Default.sidebarMenu).then(res => {
        if (res.data.success) {
          this.menuList = extendMenuList(res.data.data || [])
          localStorage.setItem('menuList', JSON.stringify(this.menuList))
          bus.$emit('init-menu-list')
          // 首页
          if (this.$router.currentRoute.fullPath === '/') {
            this.store.commit('changeActiveMenuId', 0)
          } else {
            // 取出激活的一级菜单id
            this.menuList.forEach(menu => {
              if (
                menu.pcUrl &&
                decodeURI(window.location.href).indexOf(trim(menu.pcUrl)) > -1
              ) {
                this.store.commit('changeActiveMenuId', menu.id)
              }
              menu.children.forEach(subMenu => {
                if (
                  checkUrlListInHref([subMenu.pcUrl, subMenu.word, subMenu.pic])
                ) {
                  this.store.commit('changeActiveMenuId', menu.id)
                }
                subMenu.children.forEach(thirdMenu => {
                  if (
                    checkUrlListInHref([thirdMenu.pcUrl, thirdMenu.word, thirdMenu.pic])
                  ) {
                    this.store.commit('changeActiveMenuId', menu.id)
                  }
                })
              })
            })
          }

          this.initSidebarUE()
        } else {
          // ....
        }
      })
    },
    // 定位到指定菜单
    menupPosition (step) {
      this.iScroll.scrollToElement(
        document.querySelector('#' + step),
        1200,
        null,
        null,
        window.IScroll ? window.IScroll.utils.ease.elastic : null
      )
    },
    // 查询常用功能
    getCommonMenu () {
      this.$http.post(CloudUser.commonMenu, {
        type: 3 // 2: pc功能推荐 3：pc常用菜单
      }).then(res => {
        if (res.data.success) {
          this.store.commit('changeCommonMenu', res.data.data)
        } else {
          // ....
        }
      })
    },
    // 鼠标移入
    handleMouseOver () {
      this.$forceUpdate()
    }
  },
  created () {
    this.initSidebarMenu()
    this.getCommonMenu()
    this.checkHorizontalScroll()
    window.addEventListener('resize', this.checkHorizontalScroll)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkHorizontalScroll)
  }
}
</script>
<style lang="scss" scoped>
$menu-title: 56px;

.application-center:hover {
  background-color: #353b45 !important;
}

.layout-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 120px;
  height: 100vh;
  background-color: #2f343c;
  z-index: 1998;

  & > div:first-child {
    padding-top: 70px;
    padding-bottom: $menu-title;
  }

  &_menu {
    width: 100%;
    position: relative;

    .sub-menu-warp {
      display: none;
      position: absolute;
      left: 120px;
      background-color: #2f343c;
      border: 1px solid #5E6271;
      transform: translateY(calc(-50% - (#{$menu-title} / 2)));
      .menu-wrap-box{
        display: flex;
        flex-wrap: nowrap;
        max-width: calc(100vw - 120px);
        max-height: 100vh;
      }

      .sub-menu {
        padding: 20px 24px 24px 0;
        box-sizing: border-box;
        list-style: none;
        &:first-child{
          padding-left: 30px;
        }
        &:last-child{
          padding-right: 30px;
        }

        .sub-menu-title {
          padding-bottom: 6px;
          color: #ffffff;
          opacity: 0.4;
          font-size: 14px;
        }

        .third-menu-warp {
          padding-top: 10px;
          cursor: pointer;
          width: 8em;

          .third-menu-title {
            color: #ffffff;
            font-size: 14px;
            word-break: break-all;

            &:hover {
              color: #0BB0F6 !important;
            }
          }
          .ml-2{
            margin-left: 2px;
          }
        }
      }

      .common-menu-header {
        padding: 20px 20px 10px 20px;

        .common-menu-header-title,
        .common-menu-header-btn {
          opacity: 0.5;
          color: #fff;
        }

        .common-menu-header-btn:hover {
          opacity: 1;
          color: #0bb0f6;
          cursor: pointer;
        }
      }

      .common-menu-warp {
        width: 720px;
        max-height: 500px;
        padding: 0 20px 10px 20px;

        .common-menu {
          margin: 5px 0;
          list-style: none;
          width: 135px;

          span {
            color: #fff;
            max-width: 96px;
          }
        }
      }

      .add-commonMenu {
        color: #0BB0F6;
        margin-left: 10px;
      }
    }

    &--follow {
      border-top: 1px solid #5E6271;
      border-bottom: 1px solid #5E6271;
      background: #2f343c;
      z-index: 1;
    }

    &--home {
      background: #2f343c;
      z-index: 1;
    }

    &:hover {
      .menu-title {
        background-color: #24282e;
      }
    }
  }

  &_menu-title {
    height: $menu-title;
    line-height: $menu-title;
    cursor: pointer;

    .menu-title-icon {
      width: 50px;
      text-align: center;
      opacity: 0.7;
      img {
        margin-top: -3px;
        max-width: 20px;
      }
    }

    .menu-title-text {
      color: #fff;
      opacity: 0.7;
      font-size: 14px;
      font-weight: bold;
      width: 70px;
    }

    &.active {
      background-color: #0BB0F6 !important;

      .menu-title-icon {
        opacity: 1 !important;
      }

      .menu-title-text {
        opacity: 1;
      }
    }
  }

  .app-manage {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $menu-title;
    background-color: #3b414b;
    line-height: $menu-title;
    cursor: pointer;

    .app-manage-icon {
      display: inline-block;
      width: 50px;
      text-align: center;

      img {
        margin-top: -3px;
        width: 20px;
      }
    }

    .menu-title-text {
      color: #fff;
      opacity: 0.7;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.sidebar-to-top,
.sidebar-to-bottom {
  position: absolute;
  left: 0;
  width: 120px;
  height: 14px;
  background-color: #5d6573;
  z-index: 1;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  animation: changeImage 1s infinite;
  background-image: url("../../assets/images/sidebar-to-bottom-1.png");
}

.sidebar-to-top {
  transform: rotate(180deg);
  top: 70px;
}

.sidebar-to-bottom {
  bottom: 0;
}

.sidebar-to-bottom.workbench-to-bottom {
  bottom: 17px;
}

@keyframes changeImage {
  0% {
    background-image: url("../../assets/images/sidebar-to-bottom-1.png");
  }
  25% {
    background-image: url("../../assets/images/sidebar-to-bottom-2.png");
  }
  75% {
    background-image: url("../../assets/images/sidebar-to-bottom-1.png");
  }
  100% {
    background-image: url("../../assets/images/sidebar-to-bottom-1.png");
  }
}
</style>
