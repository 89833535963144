/**
 * 获取完整的地址 基于当前的主机协议以及主机地址
 * @param {string} url 要拼装成完整地址的url片段
 * @returns {string} 返回完整的Url地址
 */
const getFullAddress = (url) => {
  if (process.env.NODE_ENV === 'development' && !url.startsWith('/webv2')) {
    return `${process.env.VUE_APP_SERVER_PROXY}${url}`
  }
  return `${window.location.protocol}//${window.location.host}${url}`
}

/**
 * 格式化时间对象为指定格式
 * @param {Date} date 日期对象
 * @param {string} type 要格式化的日期类型
 * @returns {string}  返回格式化好的日期数据
 */
const formatDate = (date, type) => {
  if (/(y+)/.test(type)) {
    type = type.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }

  Object.keys(o).forEach((key) => {
    if (new RegExp(`(${key})`).test(type)) {
      const str = o[key] + ''
      type = type.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length)
      )
    }
  })
  return type
}

/**
 * 清除首末的空字符串
 * @param {string} string 要处理的字符串
 * @returns {string} 返回处理好的字符串
 */
const trim = (string) => {
  return (string || '').replace(/^[\s\uFEFF]+|[\s\uFEFF]+$/g, '')
}

/**
 * 是否含有权限码
 * @param {string} permission 权限码
 * @returns {boolean} 结果
 */
const hasPermission = (permission) => {
  const permissionListStr = localStorage.getItem('permissionList')
  const permissionList = JSON.parse(permissionListStr)
  if (!permissionList) { return false }
  return permissionList.includes(permission)
}

/**
 * 价格格式化
 *
 * @param value 数字内容
 * @param decimals 精确位数
 * @param isPercentile boolean 是否使用千分位，默认true(千分位)
 */
const formatPrice = (value, decimals = 2, isPercentile = true) => {
  const trueValue = !isNaN(value) && value !== null ? value : 0

  let trueNumber = parseFloat(trueValue.toPrecision(16))
  if (decimals > -1) {
    trueNumber = trueNumber.toFixed(decimals)
  }

  const number = trueNumber.toString().split('.')
  let integer = number[0]
  if (isPercentile) {
    integer = integer.replace(/\d+?(?=(?:\d{3})+$)/gim, '$&,')
  }

  if (decimals !== 0) {
    const decimal = number[1] || ''
    return `${integer}.${decimal.padEnd(decimals > 0 ? decimals : 2, '0')}`
  }

  return integer
}

const tellIsIframe = () =>
  window.self.frameElement?.tagName === 'IFRAME' ||
  window.frames.length !== parent.frames.length ||
  window.self !== window.top

const yilihuoAppQrCode = 'https://jsh-oss-static.jsh.com/prod/image/2023/8/28/602a397d-ea8d-4f4e-aa7d-289045335bc1.png?x-oss-process=image/resize,m_lfit,w_95'

export {
  getFullAddress,
  trim,
  formatDate,
  hasPermission,
  formatPrice,
  tellIsIframe,
  yilihuoAppQrCode
}
