<template>
  <div :class="{ 'is-iframe': isIframe }" :style="cssVars">
    <!--顶部栏-->
    <header-component v-if="pageType === 1"/>
    <header-component-for-workbench v-if="pageType === 2"/>
    <!--侧边栏-->
    <sidebar-component ref="sidebar" v-if="pageType === 1"/>
    <sidebar-component-for-workbench ref="sidebarForWorkbench" v-if="pageType === 2"/>
    <!--pc提示弹框-->
    <msg-center
      v-if="msgtoken"
      :token="msgtoken"
      :env="msgenv"
      :code="23452345"
    />
    <!--logo-->
    <div class="layout-logo d-flex align-items-center">
      <img
        src="../assets/images/home/ylh_logo.svg"
        class="logo-icon"
      >
    </div>
    <!--主体-->
    <div class="layout-container w-100">
      <!--面包屑-->
      <div class="layout-breadcrumb" ref="breadcrumb">
        <el-breadcrumb
          v-if="breadcrumbList.length > 0"
          separator-class="el-icon-arrow-right"
          class="pt-10 pb-10"
        >
          <el-breadcrumb-item
            v-for="breadcrumb of breadcrumbList"
            :key="breadcrumb.name"
            :to="breadcrumb.to"
            :replace="breadcrumb.replace"
          >
            {{ breadcrumb.name }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="layout-content" :style="contentStyle">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"/>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"/>
      </div>
      <!--footer-->

      <!-- 只有首页才有底部 -->
      <div ref="layoutFooter" class="layout-footer" v-if="$route.path === '/home'||$route.path === '/workbench'">
        <div class="footer-content">
          <span>
            Copyright © 2019-2021 青岛巨商汇网络科技有限公司 版权所有
            <span
              @click="toPage"
              style="cursor: pointer"
            >
              鲁ICP备14033690号-6
            </span> | <span
              @click="toPage1"
              style="cursor: pointer"
            >
              鲁公网安备：37021202000771
            </span>
          </span>
          <div class="agreement">
            <span @click="toUser">
              《用户服务协议》
            </span>
            <span @click="toPrivacyAgreement">
              《隐私政策》
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from './components/header.vue'
import SidebarComponent from './components/sidebar.vue'
import bus from '../bus'
import { Default, CloudUser, CloudWorkbench } from '../apis'
import { homeIntro } from '../utils/intro'
import storeMixin from '../mixins/store'
import { trim } from '../utils'
import { getCurrentMicroApp, startsWithMicroAppPath } from '../micro-config'
import MsgCenter from '@jsh/msg-center'
import '@jsh/msg-center/dist/index.min.css'
import { mapState } from 'vuex'
// 工作台新组件
import HeaderComponentForWorkbench from './components-for-workbench/header.vue'
import SidebarComponentForWorkbench from './components-for-workbench/sidebar.vue'
import { getYLHTokenAnyway } from '../utils/auth'
import { checkUrlCurrentInTarget } from '../router/utils'

export default {
  mixins: [storeMixin],
  components: { HeaderComponent, SidebarComponent, MsgCenter, HeaderComponentForWorkbench, SidebarComponentForWorkbench },
  data () {
    return {
      isGuidePage: false, // 是否引导页面
      guidePageData: {}, // 引导页数据
      contentHeight: 0, // 内容区域高度
      breadcrumbObserver: null, // 面包屑观察器
      msgtoken: null, // 消息中台token
      msgenv: 'prod', // pc弹框环境变量
      pageType: null // 1旧首页;2新首页
    }
  },
  computed: {
    ...mapState(['isIframe']),
    // 面包屑列表
    breadcrumbList () {
      return this.store.state.breadcrumbList || []
    },
    contentStyle () {
      return {
        minHeight: this.contentHeight + 'px'
      }
    },
    cssVars () {
      return {
        '--workbench-header-height': '70px'
      }
    }
  },
  methods: {
    getHomepageShowtype () {
      // 1: app, 2: pc
      return new Promise((resolve, reject) => {
        this.$http.get(CloudWorkbench.getYlhHomepageByWhiteList, {
          terminal: 2
        }, {
          headers: {
            Authorization: 'Bearer ' + getYLHTokenAnyway()
          }
        }).then(res => {
          if (res.data.success && res.data.data) {
            this.pageType = res.data.data?.pageType || 1
            resolve()
          } else {
            this.pageType = 1
            reject(new Error('获取工作台白名单失败'))
          }
        }).catch(() => {
          this.pageType = 1
          reject(new Error('获取工作台白名单失败'))
        })
      })
    },
    // 用户协议
    toUser () {
      const newUrl = this.$router.resolve({
        name: 'userAgreement'
      })
      window.open(newUrl.href, '_blank')
    },
    // 隐私协议
    toPrivacyAgreement () {
      const newUrl = this.$router.resolve({
        name: 'privacyAgreement'
      })
      window.open(newUrl.href, '_blank')
    },
    // 备案
    toPage () {
      window.open('https://beian.miit.gov.cn')
    },
    // 备案
    toPage1 () {
      window.open('http://www.beian.gov.cn')
    },
    // 获取引导步数
    getStep () {
      this.$http.get(CloudUser.getGuidePage).then(res => {
        // const steps = []
        // res.data={"success":true,"code":0,"infoMsgs":[],"warningMsgs":[],"errorMsg":null,"data":[{"id":1,"guideSettingName":"首页引导页设置","functionCode":"home","functionName":"首页","pcUrl":"1","guideSettingDetails":[{"detailId":1,"nodeId":"homeStep1","position":"right","title":null,"description":"功能找不到，操作有困难<br>快来点这里！<br>人工客服小海同学帮您答疑解惑！","sort":1,"imgUrl":""},{"detailId":10,"nodeId":"homeStep2","position":"right","title":null,"description":"销售统计看<br>\r\n”销售统计（体验）”<br>\r\n最全最新销售数据在这里！","sort":2,"imgUrl":""},{"detailId":2,"nodeId":"homeStep3","position":"right","title":null,"description":"总部海量资源直投<br>金额补贴到店，礼品直邮到户<br>海尔卡券在这里，不要错过哦！","sort":3,"imgUrl":""}]},{"id":2,"guideSettingName":"卡卷引导页设置","functionCode":"KQ","functionName":"卡卷","pcUrl":"1","guideSettingDetails":[{"detailId":3,"nodeId":"step1","position":"bottom","title":null,"description":"一、什么是卡券？<br>\n卡券是海尔提供给门店的零售促销资源<br>\n一类：价格直降，直降金额由海尔补贴到门店；<br>\n二类：礼品，扫码出库7天后礼品由海尔直接邮<br>\n寄给用户。","sort":1,"imgUrl":""}]}]};
        if (res.data.success) {
          if (res.data.data.length > 0) {
            this.guidePageData = res.data.data
            localStorage.removeItem('guidPageObj')
            localStorage.setItem('guidPageObj', JSON.stringify(this.guidePageData))
            this.$nextTick(() => {
              this.settingGuidPage()
            })
          } else {
            bus.$emit('guidePage', true)
          }
        } else {
          bus.$emit('guidePage', true)
        }
      })
    },
    // 引导页设置
    settingGuidPage () {
      const steps = []
      if (localStorage.getItem('guidPageObj')) {
        const dataArr = JSON.parse(
          localStorage.getItem('guidPageObj')
        )
        for (const i in this.guidePageData) {
          if (this.guidePageData[i].functionCode === 'home') {
            this.isGuidePage = true
            dataArr[i].guideSettingDetails.forEach((items) => {
              steps.push({
                element: '#' + items.nodeId,
                position: items.position,
                intro: items.description
              })
            })
            setTimeout(() => {
              if (this.pageType === 1) {
                this.$refs.sidebar.menupPosition('homeStep2')
              } else if (this.pageType === 2) {
                this.$refs.sidebarForWorkbench.menupPosition('homeStep2')
              }
              homeIntro(steps)
                .start()
                .oncomplete(async () => {
                  await this.store.commit('setIntro', { home: false })
                })
                .onexit(() => {
                  this.$http.post(CloudUser.ylhGuideLog, {
                    settingId: dataArr[i].id
                  }).then(res => {
                    // const steps = []
                    if (res.data.success) {
                      dataArr.splice(i, 1)
                      localStorage.setItem('guidPageObj', dataArr)
                    } else {
                      console.log('上报失败')
                    }
                  })
                  bus.$emit('guidePage', true)
                })
            }, 1000)
            break
          } else {
            bus.$emit('guidePage', true)
          }
        }
      }
    },
    // 校准激活菜单
    checkActiveMenu (activeUrl) {
      const menuList = JSON.parse(localStorage.getItem('menuList')) || []
      let hasMenu = false
      // 首页
      if (this.$router.currentRoute.fullPath === '/home') {
        this.store.commit('changeActiveMenuId', 0)
        hasMenu = true
      } else {
        // 取出激活的一级菜单id
        menuList.forEach(menu => {
          if (
            menu.pcUrl &&
            trim(menu.pcUrl).indexOf(activeUrl) > -1
          ) {
            this.store.commit('changeActiveMenuId', menu.id)
            hasMenu = true
          }
          menu.children.forEach(subMenu => {
            if (
              checkUrlCurrentInTarget(subMenu.pcUrl, activeUrl) ||
              checkUrlCurrentInTarget(subMenu.pic, activeUrl) ||
              checkUrlCurrentInTarget(subMenu.word, activeUrl)
            ) {
              this.store.commit('changeActiveMenuId', menu.id)
              hasMenu = true
            }
            subMenu.children.forEach(thirdMenu => {
              if (
                checkUrlCurrentInTarget(thirdMenu.pcUrl, activeUrl) ||
                checkUrlCurrentInTarget(thirdMenu.pic, activeUrl) ||
                checkUrlCurrentInTarget(thirdMenu.word, activeUrl)
              ) {
                this.store.commit('changeActiveMenuId', menu.id)
                hasMenu = true
              }
            })
          })
        })
      }
      if (!hasMenu) {
        this.store.commit('changeActiveMenuId', -1)
      }
    },
    // 子应用路由跳转后
    childRouterAfterEach (to, from) {
      const currentApp = getCurrentMicroApp()
      // 从缓存中取出权限列表
      const permissionListStr = localStorage.getItem('permissionList')
      const permissionList = JSON.parse(permissionListStr)

      if (
        permissionList &&
        to.meta.permission &&
        !permissionList.includes(to.meta.permission)
      ) {
        setTimeout(() => {
          // 没有权限则跳转404页面
          this.$router.replace('/404')
        }, 60)
      }

      // 清空面包屑
      this.store.commit('changeBreadcrumbList', [])

      // 动态设置菜单面包屑
      const menuList = JSON.parse(localStorage.getItem('menuList'))
      // 该死的 to.fullPath 在 /webv2/portal/* 的页面中值会变成 /portal/*，结果就匹配到 portal 子应用去了。
      // 所以此处直接取真正的【fullPath】
      let url = window.location.pathname + window.location.hash
      if (to.meta.parentMenuPath) {
        url = to.meta.parentMenuPath
      }
      // 如果 parentMenuPath 没有加相关微应用的 publicPath 这里会基于当前地址栏地址获取到当前的微应用  然后拼上当前微应用的 publicPath
      if (currentApp.router && !startsWithMicroAppPath(url)) {
        // 保证他们连接的时候不会出现 a//b 以及 ab 的情况
        const { router } = currentApp
        const prefix = router.endsWith('/') ? router : `${router}/`
        const suffix = url.startsWith('/') ? url.slice(1) : url
        url = prefix + suffix
      }
      let breadcrumbList = []
      if (url !== '/webv2/' && url !== '/home' && menuList) {
        const getName = (list, nameList) => {
          list.forEach((menu) => {
            if (menu.pcUrl && trim(menu.pcUrl) === url) {
              breadcrumbList = [...nameList, { name: menu.name }]
            }
            if (menu.children && menu.children.length !== 0) {
              getName(menu.children, [...nameList, { name: menu.name }])
            }
          })
        }
        getName(menuList, [])
      }
      if (to.meta.extendBreadcrumbList) {
        breadcrumbList = breadcrumbList.concat(to.meta.extendBreadcrumbList)
      }

      this.store.commit('changeBreadcrumbList', breadcrumbList)

      // 菜单校准
      this.checkActiveMenu(url)
    },
    // 设置页面底色最小高度
    onSetContentMinHeight () {
      const headHeight = 70
      const restHeight = this.isIframe
        ? 0
        // 顶部栏高度 + 面包屑高度(这里取最大高度来兼容二期项目) + 底边距
        : (headHeight + this.$refs.breadcrumb.clientHeight + 20)
      // 内容高度 = 窗口高度 - 其他的高度
      this.contentHeight = window.innerHeight - restHeight
    },
    resizeListener () {
      this.onSetContentMinHeight()
    },
    // 获取消息中台token
    getmsgtoken () {
      const that = this
      that.msgenv = process.env.VUE_APP_RUN_ENV
      const msgTokenKey = 'msg_center_access_token'
      that.msgtoken = localStorage.getItem(msgTokenKey)
      if (!that.msgtoken) {
        try {
          // 重新获取令牌
          this.$http.post(CloudUser.getMsgCenterToken, {
            merchantId: '6',
            source: 'ecm'
          }).then(res => {
            if (res.data.success) {
              that.msgtoken = res.data.data.access_token
            }
          })
            .catch((e) => {
              console.error(e)
            })
        } catch (e) {
          console.error(e)
        }
      }
    }
  },
  mounted () {
    // ------设置内容区最小高度↓↓↓↓↓↓------------------------------------------------
    this.onSetContentMinHeight()
    try {
      const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
      this.breadcrumbObserver = new MutationObserver(() => {
        this.onSetContentMinHeight()
      })
      this.breadcrumbObserver.observe(this.$refs.breadcrumb, {
        childList: true,
        subtree: true
      })
    } catch (e) {
      console.warn('警告：该浏览器不支持 MutationObserver')
    }
    // ------设置内容区最小高度↑↑↑↑↑↑------------------------------------------------
    // 监听窗口变化，重新设置页面底色最小高度
    window.addEventListener('resize', this.resizeListener)
    setTimeout(() => {
      const workbenchWarp = document.getElementById('workbenchWarp')
      if (workbenchWarp) {
        this.$refs.layoutFooter.style.background = 'rgb(247,248,250)'
      }
    }, 1000)
  },
  async created () {
    this.getHomepageShowtype().then(res => {
      const localPageType = JSON.parse(localStorage.getItem('pageType') || 1)
      if (!(localPageType && localPageType === this.pageType)) {
        localStorage.setItem('pageType', this.pageType)
      }
    })
    try {
      // 获取权限码存入本地
      const res = await this.$http.get(Default.searchPermissionList)
      if (res.data.success) {
        const payload = res.data.data
        localStorage.setItem('permissionList', JSON.stringify(payload))
        this.store.commit('changePermissionList', payload)
        bus.$emit('request-permissionList-success')
      }
      // 获取价格权限码存入本地
      const res1 = await this.$http.get(CloudUser.pricePermissionList)
      if (res1.data.success) {
        const payload = res1.data.data
        localStorage.setItem('pricePermissionList', JSON.stringify(payload))
        this.store.commit('changePricePermissionList', payload)
      }
    } catch (e) {
      console.log(e)
    }
    this.deviceNumber = localStorage.getItem('browserFingerprint')
    this.getStep()
    this.getmsgtoken()
    // 子应用路由跳转之后触发事件
    bus.$on('child_router_afterEach', this.childRouterAfterEach)
  },
  beforeDestroy () {
    // 监听事件销毁
    bus.$off('child_router_afterEach')

    // 销毁面包屑观察器
    this.breadcrumbObserver && this.breadcrumbObserver.disconnect()
    this.breadcrumbObserver = null

    // 销毁窗口监听
    window.removeEventListener('resize', this.resizeListener)
  }
}
</script>
<style lang="scss" scoped>
.layout-logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 120px;
  height: var(--workbench-header-height);
  text-align: center;
  background-color: #fff;
  z-index: 1999;

  .logo-icon {
    width: 77px;
    height: 36px;
    margin: 0 auto;
  }
}

.agreement {
  cursor: pointer;
  color: #323233;
  font-weight: 500;
  margin-left: 30px;
  display: inline-block;
}

.layout-container {
  padding: var(--workbench-header-height) 20px 20px 140px;
  background-color: #f0f2f5;
  min-width: 1370px;
  min-height: 100vh;
  position: relative;
  .layout-breadcrumb {
    min-height: 20px;
  }
  .layout-content {
    background-color: #fff;
    min-height: 300px;
  }

  .layout-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding-left: 120px;
    text-align: center;
    background-color: #f0f2f5;
    font-size: 12px;

    .footer-content {
      position: relative;
      width: 100%;
      height: 100%;

      .deviceNumber {
        position: absolute;
        top: 0;
        left: 20px;
      }
    }
  }
}
.adMain {
  &::v-deep .el-dialog__header {
    padding: 0 !important;
  }
  &::v-deep .el-dialog__headerbtn {
    right: -5px !important;
    top: -5px !important;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #ddd;
  }
  &::v-deep .el-dialog__body {
    padding: 10px !important;
  }
}

.is-iframe {
  & > *:not(.layout-container),
  & > .layout-container > .layout-breadcrumb {
    display: none;
  }

  .layout-container {
    padding: 0;
    min-width: unset;
    background: #ffffff;
  }
}
</style>
